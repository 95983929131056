import t from '../../../../service/translate.service'
import moment from 'moment'
const { Table } = require("react-bootstrap")


export const OfficeWorkingHours = ({offices}) => {

    return(
        <Table>
            <thead style={{backgroundColor: "#26f8ab"}}>
                <tr>
                    <th>{t['USER_ADDRESS']}</th>
                    <th>{t['CONSULTATIONS_WORKING_HOURS']}</th>
                    <th>{t['CONSULTATIONS_APPOINTMENT_NEEDED']}</th>
                    <th>{t['CONSULTATIONS_FREQUENCY']}</th>
                </tr>
            </thead>
            <tbody>
                {offices.map((office, index) => {

                    if (!office.office_address.street || !office.office_address.city_name) {
                        return <div key={index} className='description' style={{marginTop: 20}}>
                                {t['CONSULTATIONS_NO_OFFICE_1']}<a href={`+493034655777`}>{t['CONSULTATIONS_NO_OFFICE_2']}</a>
                            </div>
                    }
                        return <tr key={index}>
                            <td><a href={`https://www.google.com/maps?q=${office.office_address.street} ${office.office_address.street_number}, ${office.office_address.zip_code} ${office.office_address.city_name}`} target='_blank' rel='noopener noreferrer'>{`${office.office_address.street} ${office.office_address.street_number}, ${office.office_address.zip_code} ${office.office_address.city_name}`}</a></td>
                            <OpeningHours openingHours={office.opening_hours} />
                        </tr>
                    })}
            </tbody>
        </Table>
    )
}

const OpeningHours = ({openingHours}) => {

    if (openingHours.length === 0) {
        return (
            <td colSpan={3}>
            <div className='description'>
                {t['CONSULTATIONS_NO_HOURS_1']} <a href={`+493034655777`}>{t['CONSULTATIONS_NO_HOURS_2']}</a>
            </div>
        </td>
        )
    }

    return (
        <>
            <td>
                {openingHours.map((openingHour, index) => {
                    const weekDay = t[`DAY_${openingHour.week_day}`] ? `(${t[`DAY_${openingHour.week_day}`]})` : ''
                    return <p key={index}>{`${moment(`${openingHour.opening_time.hours}:${openingHour.opening_time.minutes}`, "HH:m").format("HH:mm")} - ${moment(`${openingHour.closing_time.hours}:${openingHour.closing_time.minutes}`, "HH:m").format("HH:mm")} ${weekDay}`}</p>
                })}
            </td>
            <td>
                {openingHours.map((openingHour, index) => {
                return <p key={index}>{openingHour.appoitment_required ? t['CONSULTATIONS_YES'] : t['CONSULTATIONS_NO']}</p>
                })}
            </td>
            <td>
                {openingHours.map((openingHour, index) => {
                return <p key={index}>{t[`CONSULTATIONS_FREQUENCY_${openingHour.frequency}`]}</p>
                })}
            </td>
        </>
    )

}