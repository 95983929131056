import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import t from '../../service/translate.service'
import { LoyaltyPointIcon } from '../../components/icons'
import LoyaltyImageUpload from './LoyaltyImageUpload'
import LoyaltyRedeemCode from './LoyaltyRedeemCode'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import PrimaryTitle from '../../components/PrimaryTitle'
import { ImageUploader } from '../../components/formComponents'
import LoyaltyPaymentInitialMessage from './LoyaltyPaymentInitialMessage'
import { Alert } from 'antd'
import {useLocation} from 'react-router-dom';

class LoyaltyView extends Component {
  static propTypes = {
    loyalyPoints: PropTypes.string,
    location: PropTypes.object,
    navigation: PropTypes.any
  }
  constructor(props) {
    super(props)
    extendObservable(this, {
      openAreYouSure: false,
      previewImage: false,
      uploadType: '',
      wrongFileTypeError: this.props.store.uploadError,
      verificationEnabled: false,
      pointsRedeemed: false
    })
  }

  componentDidMount = async () => {
    try {
        const { location } = this.props;
        window.scrollTo(0, 0);
        const verificationEnabled = this?.props?.configStore?.verificationEnabled
        if (verificationEnabled && this.props.store.userRegistered) {
            // Handle loyalty cash out if applicable
            if (location?.state?.loyaltyCashOut && !this.pointsRedeemed) {
                await this.props.store.redeemPoints();
                this.pointsRedeemed = true;
            }
            this.verificationEnabled = true;
        }
    } catch (error) {
        console.error('Error during componentDidMount:', error);
    }
  };


  onSubmit = (e) => {
    e.preventDefault()
    this.openAreYouSure = false
    this.props.store.unregisterFromLoyaltyProgram()
  }

  onCancel = (e) => {
    this.openAreYouSure = false
  }

  onUnregister = () => {
    this.openAreYouSure = true
  }

  toggleShowAll = () => {
    this.props.store.toggleShowAll()
  }

  onSubmitImage = async (e) => {
    e.preventDefault()
    this.props.store.submitImage(this.uploadType)
  }

  removeImg = (e, img) => {
    e.preventDefault()
  }

  onImageDrop = (files, type) => {
    if (files.length) {
      this.props.store.addFile(files)
      this.uploadType = type
    } else {
      this.props.store.uploadError = t.INVALID_FILE_TYPE
    }
  }

  onError() {
    return true
  }

  onCancelUpload = (e) => {
    e.preventDefault()
    this.props.store.previewImage = false
    this.props.store.files = []
    this.props.store.uploadError = ''
  }

  backToLoyalty = (e) => {
    e.preventDefault()
    this.props.store.uploaded = false
    this.props.store.codeUploaded = false
  }

  onCancelCode = () => {
    this.props.store.code = ''
    this.props.store.redeemCode = false
  }

  handleRedeemCode = () => {
    this.props.store.redeemCode = true
  }

  handleLoyaltyPayment = () => {
    this.props.store.loyaltyPayment = true
  }

  checkYearsActive = () => {
    let isPayedOut = false
    let paidOutDate = ''

    for (let i = 0; i < this.props.store.pointsList.rows.length; i++) {
      if (this.props.store.pointsList.rows[i].text === 'Outpayment' || this.props.store.pointsList.rows[i].text === 'Einlösung Punkte') {
        paidOutDate = this.props.store.pointsList.rows[i].date
        isPayedOut = true
        break // taking last paid out date
      }
    }

    const _MS_PER_DAY = 1000 * 60 * 60 * 24
    let beginning

    if (isPayedOut) {
      beginning = new Date(paidOutDate)
    } else {
      beginning = new Date(this.props.store.createdAt)
    }

    let today = new Date()
    const utc1 = Date.UTC(beginning.getFullYear(), beginning.getMonth(), beginning.getDate())
    const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())

    let daysActive = Math.floor((utc2 - utc1) / _MS_PER_DAY)
    if (daysActive < 365) {
      return false
    } else {
      return true
    }
  }

  goBack = () => {
     this.props.navigation('/')
     this.props.store.showThankYou = false
  }

  render() {
    const { store, handleClick } = this.props
    const { pointsList, files, uploaded, uploadError, previewImage, redeemCode, codeUploaded, submitRedeemCode, codeUploadError, pointsAwarded, loyaltyPayment, unregisterError, accountError } = store

    if (previewImage) {
      return (
        <LoyaltyImageUpload
          type={this.uploadType}
          onSubmit={this.onSubmitImage}
          files={files}
          onCancel={this.onCancelUpload}
          uploadError={uploadError} />
      )
    }

    if (redeemCode ) {
      return (
        <LoyaltyRedeemCode
          onSubmit={submitRedeemCode}
          files={files}
          onCancel={this.onCancelCode}
          uploadError={codeUploadError}
          store={store}
           />
      )
    }

    if (loyaltyPayment) {
      return (
        <LoyaltyPaymentInitialMessage
          store={store}
          verificationEnabled={this.verificationEnabled}
          userVerified={this.props.contractStore.userVerified}
          contractVerified={this.props.store.contractVerified}
          configStore={this.props.configStore}
        />
      )
    }

    return (
      <div className='loyalty-view'>
           {this.props.store.showThankYou &&
          <ThankYouOverlay title={t.THANK_YOU}
            description={t.LOYALTY_PAYMENT_CASH_OUT_THANK_YOU}
            buttonText={t.BACK_HOME}
            buttonLink='/loyalty-program'
            handleClick={this.goBack}
          />
        }
        {(unregisterError || accountError) && <Alert message={unregisterError || accountError} closable type='error' style={{marginBottom: '12px'}} />}
        <div className='total-points-wrap'>
          <PrimaryTitle title={t.LOYALTY_PROGRAM_VIEW} />
          <div className='total-points'>
            <div className='image'>
              {LoyaltyPointIcon}
            </div>
            <div className='wrap'>
              <p className='points'>{pointsList.totalPoints}</p>
              <p className='label'>{t.GCP_POINTS}</p>
            </div>
          </div>
        </div>

        <p className='upload-text bold'>{t.LOYALTY_TEXT_BOLD}</p>
        {uploadError && <Alert message={uploadError} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
        <div className='loyalty-upload'>
          <a
            href=''
            className='button primary'
            onClick={(e) => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
              this.handleRedeemCode(e)
            }}
            style={{
              textDecoration: 'none',
              color: 'unset',
              marginBottom: '26px'
            }}
          >
            {t.UPLOAD_CODE_BUTTON}
          </a>
          <ImageUploader buttonText={t.ADD_CERTIFICATE} files={files} onImageDrop={files => { this.onImageDrop(files, 'CERTIFICATE') }} />
          {(this.checkYearsActive() && pointsList.totalPoints >= 120) && (
            <a
              href=''
              className='button primary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                this.handleLoyaltyPayment(e)
              }}
              style={{
                textDecoration: 'none',
                color: 'unset',
                marginBottom: '26px'
              }}
            >
              {t.LOYALTY_CASH_OUT_BUTTON}
            </a>
          )}
          <ImageUploader buttonText={t.ADD_PROOF} files={files} onImageDrop={files => { this.onImageDrop(files, 'PROOF') }} />
        </div>

        <PointsList rows={store.pointsList.rows} />

        <div className='text-center'>
          {store.pointsList.rows > 20 && (
            <a
              href=''
              className='button secondary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                this.toggleShowAll(e)
              }}
              style={{
                textDecoration: 'none',
                color: 'unset',
                marginBottom: '26px'
              }}
            >
              {t.SHOW_ALL}
            </a>
          )}
        </div>

        <div className='text-center'>
          <a
            href=''
            onClick={(e) => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
              handleClick(e)
            }}
            className='button tertiary'
            style={{
              textDecoration: 'none',
              color: 'unset'
            }}
          >
              FAQ
          </a>
        </div>

        <div className='text-center'>
          <a 
            href=''
            onClick={(e) => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
              this.onUnregister(e)}
            }
            className='button tertiary'
            style={{
              marginTop: '10px',
              textDecoration: 'none',
              color: 'unset'
            }}
          >
            {t.UNREGISTER}
          </a>
        </div>

        {codeUploaded && <ThankYouOverlay title={t.LOYALTY_CODE_THANK_YOU}
          description={t.THANK_YOU_REDEEM_CODE_1 + pointsAwarded + t.THANK_YOU_REDEEM_CODE_2}
          buttonText={t.BACK_TO_LOYALTY}
          buttonLink='/loyalty-program'
          handleClick={this.backToLoyalty} />}
        {uploaded && <ThankYouOverlay title={t.THANK_YOU}
          description={t['THANK_YOU_' + this.uploadType]}
          buttonText={t.BACK_TO_LOYALTY}
          buttonLink='/loyalty-program'
          handleClick={this.backToLoyalty} />}
        {this.openAreYouSure && (
          <ThankYouOverlay
            title={t.UNREGISTER_TITLE}
            description={t.UNREGISTRATION_TEXT}
            buttonText={t.UNREGISTER_BUTTON_TEXT}
            buttonLink='/loyalty'
            handleClick={this.onSubmit}
            cancelButtonText={t.UNREGISTER_LINK_TEXT}
            handleCancel={this.onCancel} />)}
      </div>
    )
  }
}
LoyaltyView.propTypes = {
  store: PropTypes.object,
  handleClick: PropTypes.func
}

const PointsList = ({ rows }) => (
  <div className='points-list'>
    {rows.map((data, index) => (
      <div key={index} className='points-card'>
        <div>
          <p className='text'>{localStorage.getItem('locale') === 'en' ? data.text : data.text_de}</p>
          <p className='date'>{new Date(data.date).toLocaleDateString('de-DE')}</p>
        </div>
        <div className='points'>
          <div className='image'>
            <div>{LoyaltyPointIcon}</div>
          </div>
          <p className='point'>{data.amount}</p>
        </div>
      </div>))}
  </div>
)
PointsList.propTypes = {
  rows: PropTypes.array
}
export default inject('contractStore', 'configStore')(observer(LoyaltyView))
