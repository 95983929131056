import React, { Component } from "react";
import rentContractDocumentStore from "../../store/rentContractDocuments.store";
import pdfSvg from "../../assets/svg/pdf.svg";
import icTmpPreview from "../../assets/ic-tmp-preview.png";
import icTmpDownload from "../../assets/ic-tmp-download.png";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import withRouter from "../../components/withRouter";
import { inject, observer } from "mobx-react";
import {
  extractError,
  extractErrorFromSap,
} from "../../service/response.service";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import { extendObservable } from "mobx";
import NoContent from "../../components/NoContent";
import t from "../../service/translate.service";
import { ContractsImage } from "../../components/icons";
import { Alert } from "antd";
import moment from "moment";
import PropTypes from 'prop-types'
import ContractVerificationPage from "../Contracts/ContractVerificationPage";
import { VerificationModal } from "../../components/VerificationModal";
 
const dateContainer = {
  marginTop: 14
}

class ContractDocuments extends Component {
  static propTypes = {
    contractStore: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props);
    extendObservable(this, {
      error: "",
      loading: false,
      verificationEnabled: false,
    });
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    (async () => {
      this.loading = true
      const verificationEnabled = await this?.props?.configStore?.verificationEnabled
      try {
        if(verificationEnabled) {
          const { contractNumber } = this.props.useParams;
          const res = await this.props.contractStore.getContractVerificationInfo(
            contractNumber
          );
          if(!res) this.verificationEnabled = true
          const status = await this.props.contractStore.checkUserVerificationInfo()
          if(!status.email) {
            this.verificationEnabled = true
          } else await this.getDocuments();
        } else await this.getDocuments();
        this.loading = false
      } catch(err) {
        console.log('Error in rent contract documents:', err)
        this.loading = false
      }

    })();
  };

  downloadDocument = async (documentId, documentName) => {
    this.error = "";
    this.loading = true;
    try {
      const { contractNumber } = this.props.useParams;
      const file = await rentContractDocumentStore.getDocument(
        contractNumber,
        documentId,
        documentName
      );
      saveAs(file, documentName, {});
    } catch (err) {
      console.log(err)
      this.error =
        extractErrorFromSap(err) || t[extractError(err)] || t["ERROR"];
    }
    this.loading = false;
  };

  getDocuments = async () => {
    this.loading = true;
    this.error = "";
    try {
      const { contractNumber } = this.props.useParams;
      await rentContractDocumentStore.getDocuments(contractNumber);
    } catch (err) {
      this.error =
        extractErrorFromSap(err) || t[extractError(err)] || t["ERROR"];
    }
    this.loading = false;
  };

  render() {
    const { contractNumber } = this.props.useParams;
    if (this.loading) {
      return <LoadingPageOverlay />;
    }
    if(this.verificationEnabled) {
      return <ContractVerificationPage configStore={this.props.configStore} isAddingContract={false} redirectUrl={`/rent-contract-documents/${contractNumber}`}/>
    }
    if (rentContractDocumentStore?.documents?.length > 0) {
      return (
        <div>
          {this.error && <Alert message={this.error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
          <div className="documents-list" style={{ marginBottom: "30px" }}>
            {rentContractDocumentStore.documents.map((d) => {
              return (
                <div key={d.id} className="item">
                  <div className="container">
                    <div className="details">
                      <div className="svg">
                        <img src={pdfSvg} alt="PDF" />
                      </div>
                      <div>
                        <div className="group">
                          <div className="label">{d.name}</div>
                        </div>
                        <div className="group" style={dateContainer}>
                          <div className="label">{moment(d.createdOn).format('DD.MM.YYYY')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="actions">
                      <Link
                        className="open-preview"
                        to={`/rent-contract-documents/${this.props.useParams.contractNumber}/${d.id}/preview`}
                      >
                        <img src={icTmpPreview} alt="Preview" />
                      </Link>
                      <a
                        className="start-download"
                        onClick={() => this.downloadDocument(d.id, d.name)}
                      >
                        <img src={icTmpDownload} alt="Download" />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.error && <Alert message={this.error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
          <NoContent message={t.NO_RENT_CONTRACT_DOCUMENTS} image={ContractsImage} />
        </div>
      );
    }
  }
}

export default inject('contractStore', 'configStore')(withRouter(observer(ContractDocuments)));
