import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PrimaryTitle from "../../components/PrimaryTitle";
import { inject, observer } from "mobx-react";
import withRouter from "../../components/withRouter";
import t from "../../service/translate.service";
import { Alert, Button as AntdBtn } from "antd";
import { Input } from "../../components/formComponents";
import { Button } from "react-bootstrap";

const ContractVerificationPage = (props) => {
  const {
    contractStore,
    isAddingContract,
    redirectUrl,
    loyaltyCashOut,
    loyaltyContractNumber,
    configStore,
    rentPaymentMethodData,
    changingToMethod,
    onCancel
  } = props;

  const [selectedEmail, setSelectedEmail] = useState("");
  const [resendEmailAttempts, setResendEmailAttempts] = useState(0);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const [showSmsVerificationInput, setShowSmsVerificationInput] =
    useState(false);
  const [codeError, setCodeError] = useState("");
  const [noVerificationDataError, setNoVerificationDatError] = useState(false);
  const [userMobile, setUserMobile] = useState('')

  useEffect(() => {
    (async () => {
      const contractNumber =
        contractStore.contract.contractNumber ||
        contractStore.selectedContract.contractNumber;
      const details = await contractStore.getContractDetails(
        loyaltyContractNumber || contractNumber
      );
      const hasVerificationData = await contractStore.checkEmailsInTenants(
        details
      );
      if (!hasVerificationData) setNoVerificationDatError(true);
    })();
  }, [contractStore]);

  const verifyUserEmail = (email) => {
    const canSendEmail = checkIfCanResendEmail();
    if (!canSendEmail) return;
    setSelectedEmail(email);
    contractStore
      ?.verifyEmailViaEmail(
        email,
        isAddingContract,
        redirectUrl,
        loyaltyCashOut,
        loyaltyContractNumber,
        rentPaymentMethodData,
        changingToMethod
      )
      .catch(() => console.log("error while verifying user"));
  };

  const formatEmail = (email) => {
    if (email.length) {
      const [name, domain] = email.split("@");
      return `${name[0]}${"*".repeat(name.length - 3)}@${domain}`;
    }
    return email;
  };

  const formatMobile = (mobile) => {
    if (!mobile || mobile.length < 5) {
      return mobile;
    }
    mobile = mobile
      .trim()
      .toString()
      .replace(/[^0-9+]/g, "");
    const start = mobile.slice(0, 4);
    const end = mobile.slice(-2);
    const obfuscatedMiddle = "*".repeat(mobile.length - 5);
    return `${start}${obfuscatedMiddle}${end}`;
  };

  const onSubmitCode = (e) => {
    e.preventDefault()
    if (!code) {
      setCodeError(t.CONTRACT_CODE_ERROR);
      return;
    }
    contractStore
      .verifyEmailViaCode(
        code,
        contractStore.contract.contractNumber ||
          contractStore.selectedContract.contractNumber
      )
      .then((res) => {
        const data = {
          isAddingContract: isAddingContract,
          redirectUrl: redirectUrl,
          loyaltyCashOut: loyaltyCashOut,
          contractNumber:
            contractStore.contract.contractNumber ||
            contractStore.selectedContract.contractNumber,
          address: contractStore.contract.address,
          hash: res?.hash,
          changingToMethod: changingToMethod
        };
        if(rentPaymentMethodData) data.rentPaymentMethodData = rentPaymentMethodData
        navigate("/contracts/email-verification", { state: data });
      }).catch((err) => console.log('Error while verifying via sms'))
  };

  const verifyUserMobile = (mobile) => {
    setUserMobile(mobile)
    const canSendSMS = checkIfCanResendEmail();
    if (!canSendSMS) return;
    contractStore.sendEmailVerificationSms(mobile).then(() => {
      setShowSmsVerificationInput(true);
    }).catch((error) => console.log('error sending code', error))
  };

  const checkIfCanResendEmail = () => {
    const attempts = resendEmailAttempts + 1;
    setResendEmailAttempts(attempts);
    if (resendEmailAttempts >= 3) return false;
    else return true;
  };

  const { error, verificationEmailSent, contractEmails } = contractStore;
  return (
    <div 
      className="thank-you-overlay" 
      style={{ 
        padding: "10rem",
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
     }}>
        {error && <Alert type="error" message={error} />}
        <PrimaryTitle title={t.CONTRACT_VERIF_PAGE_TITLE_1} />
        {noVerificationDataError ? (
          <div>{t.NO_VERIFICATION_DATA}</div>
        ) : (
          <>
            {showSmsVerificationInput ? (
              <>
                <div style={{ fontSize: 20 }}>{t.CONTRACT_CODE_TEXT}</div>
                {codeError && (
                  <Alert
                    style={{ marginTop: 10 }}
                    type="error"
                    message={codeError}
                  />
                )}
                <div style={{ marginBottom: 20 }}>
                  <Input
                    type="text"
                    placeholder={t.ENTER_CONTRACT_CODE}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <AntdBtn
                  disabled={resendEmailAttempts >= 3}
                  style={{ marginBottom: 10, height: 50 }}
                  className="button"
                  onClick={() => verifyUserMobile(userMobile)}
                >
                  {t.CONTRACT_VERIF_RESEND_SMS} {formatMobile(userMobile)}
                </AntdBtn>

                <button
                  onClick={(e) => onSubmitCode(e)}
                  className="button primary"
                >
                  {t.CONTRACT_VERIF_CONTINUE}
                </button>
              </>
            ) : (
              <>
                <div className="description" style={{textAlign: "center"}}>
                  {verificationEmailSent
                    ? `${t.CONTRACT_VERIF_PAGE_TITLE_3}${formatEmail(
                        selectedEmail
                      )}. ${t.CONTRACT_VERIF_PAGE_TITLE_4}`
                    : t.CONTRACT_VERIF_PAGE_TITLE_2}
                </div>
                {resendEmailAttempts >= 3 && (
                  <div style={{ marginBottom: 15 }}>
                    {t.CONTRACT_VERIF_PAGE_TITLE_5}
                  </div>
                )}

                {configStore.verificationEnabled &&
                  contractEmails.length > 0 &&
                  contractEmails.map((tenant, index) => (
                    <React.Fragment key={index}>
                      {configStore.verificationMethodEmail && tenant.email && (
                        <AntdBtn
                          disabled={resendEmailAttempts >= 3}
                          style={{ marginBottom: 10, height: 50 }}
                          className="button"
                          onClick={() => verifyUserEmail(tenant.email)}
                        >
                          {verificationEmailSent
                            ? `${t.CONTRACT_VERIF_RESEND} ${formatEmail(
                                tenant.email
                              )}`
                            : `Email (${formatEmail(tenant.email)})`}
                        </AntdBtn>
                      )}
                      {configStore.verificationMethodSms && tenant.mobile && (
                        <AntdBtn
                          disabled={resendEmailAttempts >= 3}
                          style={{ marginBottom: 10, height: 50 }}
                          className="button"
                          onClick={() => verifyUserMobile(tenant.mobile)}
                        >
                          SMS {formatMobile(tenant.mobile)}
                        </AntdBtn>
                      )}
                      {configStore.verificationMethodSms &&
                        tenant.telephone && (
                          <AntdBtn
                            disabled={resendEmailAttempts >= 3}
                            style={{ marginBottom: 10, height: 50 }}
                            className="button"
                            onClick={() => verifyUserMobile(tenant.telephone)}
                          >
                            SMS {formatMobile(tenant.telephone)}
                          </AntdBtn>
                        )}
                    </React.Fragment>
                  ))}
              </>
            )}
          </>
        )}

        {!props.onCancel ? (
          <Link to="/" className="button tertiary" style={{width: '120px', margin: '0 auto' }}>
            {t.FORM_CANCEL}
          </Link>
        ) : (
          <button onClick={props.onCancel}>{t.FORM_CANCEL}</button>
        )}
    </div>
  );
}

export default inject("contractStore")(
  withRouter(observer(ContractVerificationPage))
);
