import React from 'react'
import { observer } from 'mobx-react'
import { Form, Button } from 'react-bootstrap'
import { Input } from '../../components/formComponents'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'
import { Alert } from 'antd'

const LoyaltyRedeemCode = observer(({ store, onCancel, onSubmit, uploadError }) => {
  const { setCode, code } = store
  return (
    <div className='loyalty-upload module'>
      <div className='loyalty-code-top text-center'>
        <PrimaryTitle title={t.UPLOAD_CODE_BUTTON} />
        <p>{t.LOYALTY_CODE_SUBTITLE}</p>
      </div>
      <Form>
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Input
              value={code}
              placeholder={t.LOYALTY_CODE_PLACEHOLDER}
              onChange={e => setCode(e.target.value)}
              styles={{ marginBottom: '1rem' }}
            />

            <a
              href=''
              type='submit'
              className='button primary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                onSubmit(e)
              }}
              style={{
                textDecoration: 'none',
                color: 'unset'
              }}
            >
              {t.LOYALTY_SUBMIT_CODE}
            </a>
          </div>
        </Form.Group>
      </Form>
      {uploadError && <Alert message={uploadError} type='error' />}
      <a
        href=''
        className='button tertiary'
        onClick={(e) => {
          if (e.key !== 'Tab') {
            e.preventDefault()
          }
          onCancel(e)
        }}
        style={{
          textDecoration: 'none',
          color: 'unset'
        }}
      >
        {t.FORM_CANCEL}
      </a>
    </div>
  )
})

export default LoyaltyRedeemCode
