import { api } from '../service/api.service'

const EVENT_ENDPOINT = `/offer`

async function getAll (skip, limit) {
  return await api.get(EVENT_ENDPOINT, { params: { skip: skip || 0, limit: limit || 0 } })
    .then(res => res.data)
}

function getOne (id) {
  return api.get(EVENT_ENDPOINT + '/' + id)
    .then(res => res.data)
}

export { getAll, getOne }
