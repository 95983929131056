import { useState } from "react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { extendObservable } from "mobx";
import icTmpDownload from "../../assets/ic-tmp-download.png";
import icTmpPreview from "../../assets/ic-tmp-preview.png";
import pdfSvg from "../../assets/svg/pdf.svg";
import { LettersRegistration } from "./LettersRegistration";
import { Checkbox } from "../../components/formComponents";
import t from "../../service/translate.service";
import { LettersUnregister } from "./LettersUnregister";
import ContractSelector from "../../components/contractSelector";
import LetterStore from "../../store/letters.store";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import { ContractsImage } from "../../components/icons";
import ViewDocument from "../Documents/ViewDocument";
import { Buffer } from "buffer";
import DOMPurify from "dompurify";
import { Pagination } from "antd";
import historyService from "../../service/history.service";
import NoContent from "../../components/NoContent";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import moment from "moment";
import withRouter from "../../components/withRouter";
import propTypes from "prop-types";

const paginationContainerStyle = {
  textAlign: "center",
  marginTop: 10,
};
const dateContainer = {
  marginTop: 14,
};
class Letters extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired,
    consumptionStore: propTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.store = new LetterStore();
    this.state = {
      showConfirmModal: false,
    };
    this.contractStore = this.props.contractStore;
    this.consumptionStore = this.props.consumptionStore;
    this.locale = localStorage.getItem("locale") || "de";
    extendObservable(this, {
      file: null,
      preview: false,
      isLettersConsumptionEnabled: this.consumptionStore.lettersToApp,
      lettersConsumptionUnregisterModal:
        this.store.lettersConsumptionUnregisterModal,
    });
  }

  componentDidMount() {
    if (this?.contractStore?.contracts) {
      (async () => {
        const contracts = this?.contractStore?.activeContracts;
        this.store.setLoading(true);
        this.store.setLoaded(false);
        this.locale === "de"
          ? registerLocale("de", de)
          : registerLocale("en", enUS);
        for (let i = 0; i < contracts.length; i++) {
          const contract = contracts[i];
          const result = await this.store.setIsLettersConsumptionEnable(
            contract,
            true
          );
          if (result) {
            break;
          }
        }
        this.store.setLoading(false);
        this.store.setLoaded(true);
      })();
    }
  }

  onDownload = async (letter) => {
    await this.store.trackLetters(
      this.contractStore.selectedContract,
      "download",
      letter
    );
    const res = await this.store.onLetterDownload(letter);
    const buffer = Buffer.from(res.file, "base64");
    const blob = new Blob([buffer]);
    let url = window.URL.createObjectURL(blob);
    url = DOMPurify.sanitize(url);
    const a = document.createElement("a");
    a.setAttribute("style", "display: none;");
    a.href = url;
    a.download = letter.name + ".pdf";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  openPreviewModal = async (letter) => {
    try {
      await this.store.trackLetters(
        this.contractStore.selectedContract,
        "preview",
        letter
      );
      const res = await this.store.onLetterDownload(letter);
      const buffer = Buffer.from(res.file, "base64");
      const blob = new Blob([buffer], { type: "application/pdf" });
      this.file = blob;
      this.preview = true;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch {
      this.preview = false
    }
  };

  onClose = () => {
    this.file = null;
    this.preview = false;
  };

  render() {
    const {
      isLettersConsumptionEnabled,
      lettersConsumptionUnregisterModal,
      onSubscriptionChange,
      onRegister,
      onUnregisterCancel,
      letters,
      loading,
      loaded,
      page,
      errorRegistration,
      count,
      onPageChange,
    } = this.store;

    if (!this.props.contractStore.selectedContract) {
      return (
        <div>
          <div className="text-center">
            <Link
              to="/contracts/add-contract"
              className="button primary"
              style={{ marginBottom: "30px" }}
              onClick={() => historyService.push("/contracts/add-contract")}
            >
              {t.ADD_A_CONTRACT_BUTTON}
            </Link>
          </div>
          <NoContent message={t.NO_CONTRACTS} image={ContractsImage} />
        </div>
      );
    }
    if (!isLettersConsumptionEnabled) {
      return (
        <LettersRegistration
          error={errorRegistration}
          isLettersConsumptionEnabled={isLettersConsumptionEnabled}
          loaded={loaded}
          loading={loading}
          handleClick={async () =>
            await onRegister(this.contractStore.selectedContract, true)
          }
          handleCancel={() => this.props.navigate("/tenant-services")}
          setIsLettersConsumptionEnable={
            this.store.setIsLettersConsumptionEnable
          }
          contract={this.contractStore.selectedContract}
        />
      );
    }

    if (lettersConsumptionUnregisterModal) {
      return (
        <LettersUnregister
          error={errorRegistration}
          loading={loading}
          onHandleConfirm={async () => {
            await onRegister(this.contractStore.selectedContract, false);
            this.props.navigate("/");
          }}
          onHandleCancel={onUnregisterCancel}
        />
      );
    }

    return (
      <>
        {loading && <LoadingPageOverlay />}
        {this.preview ? (
          <ViewDocument file={this.file} onClose={this.onClose} />
        ) : (
          <>
            <div
              className="energy-contract-picker"
              style={{ marginBottom: 15 }}
            >
              {!this.preview && (
                <ContractSelector page="letters" label padding />
              )}
            </div>
            <div className="documents-list">
              {letters && letters.length > 0 ? (
                letters.map((letter, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="container">
                        <div className="details">
                          <div className="svg">
                            <img src={pdfSvg} />
                          </div>

                          <div>
                            <div className="group">
                              <div className="label">{letter.name}</div>
                            </div>
                            <div className="group" style={dateContainer}>
                              <div className="label">
                                {moment(letter.date).format("DD.MM.YYYY")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="actions">
                          <button
                            className="open-preview"
                            href={"/letter/" + "letter-name"}
                            onClick={() => this.openPreviewModal(letter)}
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent'
                            }}
                          >
                            <img src={icTmpPreview} />
                          </button>
                          <button
                            className="start-download"
                            onClick={(e) => this.onDownload(letter)}
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent'
                            }}
                          >
                            <img src={icTmpDownload} />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoContent
                  message={t.NO_LETTERS_MESSAGE}
                  image={ContractsImage}
                />
              )}
              {letters.length > 0 && (
                <div style={paginationContainerStyle}>
                  <Pagination
                    current={page}
                    total={count}
                    pageSize={10}
                    onChange={(p) => onPageChange(p)}
                    showSizeChanger={false}
                  />
                </div>
              )}
              <div
                className="energy-actions-wrapper"
                style={{ position: "absolute", bottom: "20px" }}
              >
                <div className="checkbox-holder">
                  <Checkbox
                    labelBeforeText={t.HEATING_AND_ENERGY_CHECKNOX_TEXT}
                    value={isLettersConsumptionEnabled}
                    onChange={() =>
                      onSubscriptionChange(!isLettersConsumptionEnabled)
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default inject(
  "contractStore",
  "consumptionStore"
)(withRouter(observer(Letters)));
