import t from './translate.service'
import find from 'lodash/find'
import each from 'lodash/each'
import moment from 'moment'

export const buildValidators = (validators, submitted) => {
  const _validators = {}
  each(validators, (validators, field) => {
    if (!submitted) return null
    _validators[field] = (val, f) => {
      const faultyValidator = find(validators, validator => validator(val, f))
      return faultyValidator && faultyValidator(val, f)
    }
  })

  return _validators
}

export const passwordValidation = (val, label) => {
  if (val.search(/[a-z]/) === -1) {
    return t['VALIDATE_EMPTY_MIN_ONE_LOWER_CASE_LETTER']
  }
  if (val.search(/[A-Z]/) === -1) {
    return t['VALIDATE_EMPTY_MIN_ONE_CAPITAL_LETTER']
  }
  if (val.search(/[0-9]/) === -1) {
    return t['VALIDATE_EMPTY_MIN_ONE_NUMBER']
  }
  // eslint-disable-next-line
  if (val.search(/[-!$%^&*()_+|~=`'"\\{}\[\]:\/;<>?,.@#]/) === -1) {
    return t['VALIDATE_EMPTY_MIN_ONE_SPECIAL_CHARACTER']
  }
}

export const checkPasswordResponse = (response) => {
  switch (response) {
    case 'wrong_password':
      return t['WRONG_PASSWORD_CHANGE_PASSWORD']
    case 'min_length':
      return t['VALIDATE_MIN_LENGTH']('Password', 10)
    case 'max_length':
      return t['VALIDATE_MAX_LENGTH']('Password', 50)
    case 'require_lower_case_letter':
      return t['VALIDATE_EMPTY_MIN_ONE_LOWER_CASE_LETTER']
    case 'require_capital_letter':
      return t['VALIDATE_EMPTY_MIN_ONE_CAPITAL_LETTER']
    case 'require_number_character':
      return t['VALIDATE_EMPTY_MIN_ONE_NUMBER']
    case 'LIMIT_EXCEEDED':
      return t['LIMIT_EXCEEDED']
    case 'require_special_character':
      return t['VALIDATE_EMPTY_MIN_ONE_SPECIAL_CHARACTER']
  }
}

export const checkEmailResponse = (response) => {
  switch (response) {
    case 'EMAIL_ALREADY_IN_USE':
      return t['EMAIL_IN_USE']
    default:
      return t['DAMAGE_REPORT_ON_ERROR']
  }
}

export const requiredCheckbox = (val, label) => {
  return !val && t['VALIDATE_REQUIRED'](label)
}

export const requiredFormField = (val, label) => !val && t['VALIDATE_FORM_FIELD'](label)
export const required = (val, label) => !val && t['VALIDATE_REQUIRED'](label)
export const requiredName = (val, label) => !val && t['VALIDATE_NAME'](label)

export const requiredLastName = (val, label) => !val && t['VALIDATE_LASTNAME'](label)

export const emptyPass = (val, label) =>
  !val && t['VALIDATE_EMPTY_PASSWORD'](label)

export const minCharWithoutSpace = len => (val, label) => {
  const regex = new RegExp('^(?:[^a-zA-Z0-9]*[a-zA-Z0-9]){' + len + '}')
  return val && !regex.test(val) && t['VALIDATE_MIN_LENGTH'](label, len)
}

export const minLength = len => (val, label) =>
  val && val.length < len && t['VALIDATE_MIN_LENGTH'](label, len)

export const maxLength = len => (val, label) =>
  val && val.length > len && t['VALIDATE_MAX_LENGTH'](label, len)

export const betweenLength = (min, max) => (val, label) => {
  if (val.length < min || val.length > max) return t['VALIDATE_BETWEEN_LENGTH'](label, min, max)
}

export const isValidBIC = (val, label) => {
  if (!val) {
    return t['VALIDATE_BIC'](label)
  } else {
    const r = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
    return !r.test(val) && t['VALIDATE_BIC'](label)
  }
}

export const isValidIBAN = (val, label) => {
  if (!val) {
    return t['VALIDATE_IBAN'](label);
  } else {
    val = val.replace(/\s/g, '').toUpperCase();

    const countryCode = val.slice(0, 2);
    const length = getIBANLength(countryCode);
    if (!length) {
      return t['VALIDATE_IBAN'](label);
    }

    if (val.length !== length) {
      return t['VALIDATE_IBAN'](label);
    }

    const rearranged = val.slice(4) + val.slice(0, 4);
    const converted = rearranged
      .split('')
      .map((char) => {
        if (/[0-9]/.test(char)) {
          return char;
        }
        return (char.charCodeAt(0) - 55).toString();
      })
      .join('');

    let remainder = '';
    for (let i = 0; i < converted.length; i++) {
      remainder = (
        parseInt(remainder + converted[i]) % 97
      ).toString();
    }

    return !parseInt(remainder) === 1 && t['VALIDATE_IBAN'](label);
  }
};

function getIBANLength(countryCode) {
  const lengths = {
    AD: 24,
    AT: 20,
    BE: 16,
    BG: 22,
    HR: 21,
    CY: 28,
    CZ: 24,
    DK: 18,
    EE: 20,
    FI: 18,
    FR: 27,
    DE: 22,
    GI: 23,
    GR: 27,
    HU: 28,
    IS: 26,
    IE: 22,
    IT: 27,
    LV: 21,
    LI: 21,
    LT: 20,
    LU: 20,
    MT: 31,
    MC: 27,
    NL: 18,
    NO: 15,
    PL: 28,
    PT: 25,
    RO: 24,
    SM: 27,
    SK: 24,
    SE: 24,
    CH: 21,
    GB: 22,
    VA: 22,
  };
  return lengths[countryCode] || null;
}

export function email(val, label) {
  if (!val) {
    return t['VALIDATE_EMAIL'](label)
  } else {
    // eslint-disable-next-line
    const r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !r.test(val) && t['VALIDATE_EMAIL'](label)
  }
}

export const emailConfirm = email => (val, label) =>
  val && val !== email && t['VALIDATE_EMAIL_CONFIRM'](label)

export const emailChangeConfirm = email => (val, label) =>
  val && val !== email && t['VALIDATE_EMAIL_CHANGE_CONFIRM']

export function phone(val, label) {
  if (!val) {
    return t['PHONE_FORMAT']
  } else {
    const r = /^(\+|0)[0-9|(|)|-]{10,}$/
    return !r.test(val) && t['PHONE_FORMAT']
  }
}

export function phone2(val, label) {
  if (val) {
    const r = /^(\+|0)[0-9|(|)|-]{10,}$/
    return !r.test(val) && t['PHONE_FORMAT']
  }
}

export const password = passwordConfirm => (val, label) =>
  val && val !== passwordConfirm && t['VALIDATE_PASSWORD'](label)

export function birthday(val) {
  if (!val) return false
  return val && moment(val).format('YYYY/MM/DD') !== val && t['VALIDATE_DATE']
}
