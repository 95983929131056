import React from 'react'
import { Link } from 'react-router-dom'
import PrimaryTitle from '../components/PrimaryTitle'
import PropTypes from 'prop-types'
import { VerificationModal } from './VerificationModal'

const ThankYouOverlay = ({ title, description, buttonText, buttonLink, handleClick, cancelButtonText, handleCancel, showModal, onCloseModal, style  }) => {
  return (
    <div className='thank-you-overlay' style={style ? style : {}}>
      <div className='wrapper'>
        {showModal &&
        <VerificationModal isOpen={showModal} onClose={onCloseModal}/>}
        <PrimaryTitle title={title} />
        <div className='description'>{description}</div>
        {(buttonLink || handleClick) && <Link style={{ marginTop: '10px' }} to={buttonLink} className='button primary' onClick={handleClick}>{buttonText}</Link>}
        {cancelButtonText && <Link style={{ marginTop: '10px' }} className='button tertiary' to='' onClick={handleCancel}>{cancelButtonText}</Link>}
      </div>
    </div>
  )
}
ThankYouOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
  handleClick: PropTypes.func,
  cancelButtonText: PropTypes.string,
  handleCancel: PropTypes.func
}
export default ThankYouOverlay
