import React from 'react'
import { Link } from 'react-router-dom'
import User from '../store/user.store'
import PropTypes from 'prop-types'

const Submenu = ({ currentPage, item, visible, linkClick, loyaltyActive, configStore, isCondominium }) => {
  if (!visible) return null
  return (
    <div className='nav-link-wrapper sub-nav'>
      <ul className='navbar-nav mr-auto'>
        { item.submenu.map(i => {
          if (i.path === '/loyalty-program' && !loyaltyActive) return
          if (i.path === '/heating-and-energy' && !configStore?.heatingAndEnergyEnabled) return
          if (i.path ===  '/receipt-inspection' && !configStore?.ocInspectionEnabled) return
          var iconClass = ''
          if (i.path === '/loyalty-program') iconClass = ' loyalty'
          var linkClass = ''
          if (i.path === '/loyalty-program' && User.user.resident) linkClass = ' disabled'
          if (localStorage.getItem('locale') === 'de') iconClass += ' de'

          i.active = currentPage === i.path || currentPage.includes(i.path)
          if (i?.isCondominium && isCondominium) return
          return (
            <li
              key={i.path}
              className={`nav-item sub-nav ${i.active && 'active'} ${iconClass && iconClass} ${linkClass && linkClass}`}
            >
              <Link to={i.path} className='nav-link' onClick={(e) => linkClick(i.path)}
                data-bs-toggle='collapse'
                data-bs-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'>
                <span>
                  {i.svg}
                  {i.name}
                </span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
Submenu.propTypes = {
  currentPage: PropTypes.string.isRequired,
  linkClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  loyaltyActive: PropTypes.bool.isRequired,
  configStore: PropTypes.object.isRequired
}
export default Submenu
