/**
 * Page contains the whole login form, it's small enough not to be separated.
 */
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import t from "../service/translate.service";
import PrimaryTitle from "../components/PrimaryTitle";
import LoginRegisterSwitcher from "../components/LoginRegisterSwitcher";
import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "../azureB2C/authConfig";
import LoadingPageOverlay from "../components/LoadingPageOverlay";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";

const RegistrationPage = () => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);
  const language = localStorage.getItem("locale") || "de";

  const handleEmailRegister = async () => {
    localStorage.removeItem('path')
    setLoading(true);
    try {
      await instance.acquireTokenRedirect({
        ...b2cPolicies.authorities.emailSignUp,
        extraQueryParameters: { ui_locales: `${language}` },
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };


  const handleGoogleRegister = async () => {
    setLoading(true)
    const savedPath = localStorage.getItem('path')
    localStorage.removeItem('path')
    try {
      await instance.acquireTokenRedirect({
        ...b2cPolicies.authorities.googleSignUpSignIn,
        extraQueryParameters: {'ui_locales': `${language}`},
        redirectStartPage: savedPath || '/'
      })
    } catch(err) {
      console.log(err)
    }
    setLoading(false)
  }

  const handleAppleRegister = async () => {
    setLoading(true)
    const savedPath = localStorage.getItem('path')
    localStorage.removeItem('path')
    try {
      await instance.acquireTokenRedirect({
        ...b2cPolicies.authorities.appleSignUpSignIn,
        extraQueryParameters: {'ui_locales': `${language}`},
        redirectStartPage: savedPath || '/'
      })
    } catch(err) {
      console.log(err)
    }
    setLoading(false)
  }

  if (loading) {
    return <LoadingPageOverlay />;
  }

  return (
    <div>
      <PrimaryTitle title={t.WELCOME_TITLE} />
      <LoginRegisterSwitcher />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Button
          style={{
            width: language === 'en' ? '254px' : '247px',
            justifyContent: 'center'
          }}
          onClick={() => handleEmailRegister()}
          className="button primary"
        >
          {t.LOGIN_PAGE_REGISTER_WITH_EMAIL}
        </Button>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            marginTop: '12px',
            marginBottom: '12px'
          }}
        >
          <div
            style={{
              width: '80px',
              height: '1px',
              backgroundColor: '#acadae'
            }}
          ></div>
          <p
            style={{
              marginBottom: '1px'
            }}
          >
            {t.LOGIN_PAGE_REGISTER_OR}</p>
          <div
            style={{
              width: '80px',
              height: '1px',
              backgroundColor: '#acadae'
            }}
          ></div>
        </div>
        <Button
          style={{
            width: language === 'en' ? '254px' : '247px',
            justifyContent: 'center'
          }}
          onClick={() => handleGoogleRegister()}
          className="button google"
        >
          <FcGoogle />
          {t.LOGIN_PAGE_REGISTER_WITH_GOOGLE}
        </Button>
        <Button
          style={{
            width: language === 'en' ? '254px' : '247px',
            justifyContent: 'center'
          }}
          onClick={() => handleAppleRegister()}
          className="button apple"
        >
          <FaApple />
          {t.LOGIN_PAGE_REGISTER_WITH_APPLE_ID}
        </Button>
      </div>
    </div>
  );
};

export default RegistrationPage;
