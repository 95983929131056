import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider, observer } from 'mobx-react'
import Routes from './Routes'
import './index.css'
import validator from 'validatorjs'
import { stores } from './store'
import t from './service/translate.service'
import { interceptor } from './service/api.service'
// require('./polyfill.js')
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './azureB2C/authConfig'
const msalInstance = new PublicClientApplication(msalConfig)

validator.useLang(t.locale)
interceptor()
const root = createRoot(document.getElementById('root'))
const UserLoading = observer(() => {
  return (
    <Provider {...stores}>
      {/*<Router history={history} routes={Routes} />*/}
      <Routes />
    </Provider>
  )
})
root.render(
  <MsalProvider instance={msalInstance}>
    <UserLoading/>
  </MsalProvider>
)

 validator.register(
   'contract',
   value => {
     return value.match(
       /^[A-Z]{1,2}\d{2,4}_[A-Z]_[A-Z]{0,2}\d{1,5}\.[A-Z]{0,2}\d{2,3}$/
     )
   },
   t.INVALID_CONTRACT_FORMAT
 )

 validator.register(
   'phone',
   value => {
     // eslint-disable-next-line
     return value.match(/[\+0](\s*[\(\)0-9+-]+)+/)
   },
   t.PHONE_FORMAT
 )
