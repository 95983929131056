import contractService from './contract.service'
import ProfileStore from "../store/profile.store";
import userStore from '../store/user.store';

const profileService = new ProfileStore

export const checkIsUserCondominium = (contracts) => {
  const condominiumContracts = checkCondominiumContracts(contracts)
  const activeCondominiumContracts = checkActiveContracts(condominiumContracts)
  const activeContracts = checkActiveContracts(contracts)
  if (contracts?.length === 0) {
    return false
  } else if (contracts?.length === condominiumContracts?.length) {
    return true
  } else if (activeContracts?.length === 0) {
    return false
  } else if ( activeCondominiumContracts?.length === activeContracts?.length) {
    return true
  } else {
    return false
  }
} 

export const checkIfUserHasMixedContracts = (contracts) => {
  const condominiumContracts = checkCondominiumContracts(contracts)
  const activeCondominiumContracts = checkActiveContracts(condominiumContracts)
  const activeContracts = checkActiveContracts(contracts)
  if (contracts?.length === 0) {
    return false
  } else if ( activeCondominiumContracts?.length !== activeContracts?.length) {
    return true
  }
}

const checkActiveContracts = (contracts) => {
  return contracts.filter((contract) => contract?.active && contract?.valid)
}

const checkCondominiumContracts = (contracts) => {
  return contracts?.filter((contract) => contractService.checkIsContractCondominium(contract?.contractNumber))
}

export const welcomeWizardRedirect = (user) => {
  const wasShown = window.localStorage.getItem('welcomeWizardShown')
  if (wasShown && wasShown !== 'true') {
    if (JSON.parse(wasShown)?.find((item) => item.user_id === user.id)) {
      // Nothing to do for now
    } else {
      window.localStorage.setItem('welcomeWizardShown', JSON.stringify([...JSON.parse(wasShown), { user_id: user.id, seen: true }]))
      window.location.replace('/onboarding')
    }
  } else {
    window.localStorage.setItem('welcomeWizardShown', JSON.stringify([{ user_id: user.id, seen: true }]))
    window.location.replace('/onboarding')
  }
}

export const postRedirectHandler = async (tokenClaims, instance) => {
  try {
    switch (tokenClaims?.acr) {
      case 'b2c_1a_signin':
      case 'b2c_1a_signin_prod':
        localStorage.setItem('auth_grant', 'sign_in')
        localStorage.removeItem('socialLogin')
      break;
      case 'b2c_1a_signup':
      case 'b2c_1a_signup_prod':
        localStorage.setItem('auth_grant', 'sign_up')
        localStorage.removeItem('socialLogin')
      break;
      case 'b2c_1a_signup_signin_apple':
      case 'b2c_1a_signup_signin_apple_prod':
        localStorage.setItem('auth_grant', 'sign_up_sign_in_apple')
        localStorage.setItem('socialLogin', 'true')
      break;
      case 'b2c_1a_signup_signin_google':
      case 'b2c_1a_signup_signin_google_prod':
        localStorage.setItem('auth_grant', 'sign_up_sign_in_google')
        localStorage.setItem('socialLogin', 'true')
      break;
      case 'b2c_1a_profileedit':
      case 'b2c_1a_profileedit_prod':
        localStorage.setItem('profile_edited', '1')
      break;
      case 'b2c_1a_emailchange':
      case 'b2c_1a_emailchange_prod':
        localStorage.setItem('emailChanged', '1')
      break;
      case 'b2c_1a_passwordchange':
      case 'b2c_1a_passwordchange_prod':
        localStorage.setItem('passwordReseted', '1')
        localStorage.setItem('auth_grant', 'passwordchange')
      break;
      case 'b2c_1a_passwordforgot':
      case 'b2c_1a_passwordforgot_prod':
        localStorage.setItem('passwordReseted', '1')
        localStorage.setItem('auth_grant', 'passwordreset')
      break;
      default:
        console.log(tokenClaims)
    }

    await userStore.postRedirectHandler(tokenClaims)

    switch (tokenClaims?.acr) {
      case 'b2c_1a_emailchange':
      case 'b2c_1a_emailchange_prod':
      case 'b2c_1a_passwordreset':
      case 'b2c_1a_passwordreset_prod':
      case 'b2c_1a_passwordforgot':
      case 'b2c_1a_passwordforgot_prod':
      case 'b2c_1a_passwordchange':
      case 'b2c_1a_passwordchange_prod':
        await instance.logout()
      break;
      default:
        console.log(tokenClaims)
    }
    
  } catch (err) {
    throw err
  }
}
