import React, { useEffect, useState } from 'react'
import PrimaryTitle from '../../../../components/PrimaryTitle'
import { Input, DateInput } from '../../../../components/formComponents'
import { Form, Button } from 'react-bootstrap'
import DocumentStore from '../../../../store/documentForm.store'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import t from '../../../../service/translate.service'
import ContractSelector from '../../../../components/contractSelector'

const OperatingCostsPrepayment = (props) => {
  const { model, setVal, operatingCostValidators } = DocumentStore
  const { file } = props

  const onSubmit = async (e) => {
    e.preventDefault()
    DocumentStore.submit(file.fileName, props.contractStore.selectedContract.contractNumber)
  }
  const getAttrs = field => {
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: operatingCostValidators[field]
    }
  }

  return (
    <div className='form-holder'>
      <PrimaryTitle title={t.OPERATING_COST_PREPAYMENT} />
      <Form>
        <ContractSelector isDigitalForm={true} label padding page='documents' loadPrepayments={DocumentStore.loadPrepayments} />
        <h3 className='input-text-inline'>
          {t.OPERATING_COST_PREPAYMENT_HEAD_ONE_A}
          <Input value={model.operatingCostOn} name='operatingCostOn' {...getAttrs('operatingCostOn')} label={t.DIGITAL_CONTRACT_FORM_ON + '*'} isLabel={false} />€
          {t.OPERATING_COST_PREPAYMENT_HEAD_ONE_B}
          <Input value={model.heatingCostOn} name='operatingCostRaise' {...getAttrs('operatingCostRaise')} label={t.DIGITAL_CONTRACT_FORM_RAISE + '*'} isLabel={false} /> €
          {t.OPERATING_COST_PREPAYMENT_HEAD_ONE_C}
        </h3>
        <h3 className='input-text-inline'>
          {t.OPERATING_COST_PREPAYMENT_HEAD_TWO_A}
          <Input name='heatingCostOn' {...getAttrs('heatingCostOn')} label={t.DIGITAL_CONTRACT_FORM_ON + '*'} isLabel={false} />€  
          {t.OPERATING_COST_PREPAYMENT_HEAD_ONE_B}
          <Input name='heatingCostRaise' {...getAttrs('heatingCostRaise')} label={t.DIGITAL_CONTRACT_FORM_RAISE + '*'} isLabel={false} /> €
          {t.OPERATING_COST_PREPAYMENT_HEAD_ONE_C}
        </h3>
        <h3 className='input-text-inline'>
          {t.OPERATING_COST_PREPAYMENT_HEAD_THREE_A}
          <DateInput name='operatingCostPayDate' value={model['operatingCostPayDate']} onChange={(date) => setVal('operatingCostPayDate', date)} validate={operatingCostValidators['operatingCostPayDate']} label={t.DIGITAL_CONTRACT_FORM_DATE + '*'} isLabel={false} />
          {t.OPERATING_COST_PREPAYMENT_HEAD_THREE_B}
        </h3>
        <div className='group-space' />
        <Input name='tenantNo' {...getAttrs('tenantNo')} label={t.DIGITAL_CONTRACT_FORM_TENANT_NUMBER + '*'} disabled />
        <Input name='fullName' {...getAttrs('fullName')} label={t.DIGITAL_CONTRACT_FORM_FULL_NAME + '*'} />
        <Input name='address' {...getAttrs('address')} label={t.DIGITAL_CONTRACT_FORM_ADDRESS + '*'} />
        <Input name='phone' {...getAttrs('phone')} label={t.DIGITAL_CONTRACT_FORM_PHONE_NUMBER + '*'} />
        <Input name='email' {...getAttrs('email')} label={'E-Mail' + '*'} />
        <Input name='place' {...getAttrs('place')} label={t.DIGITAL_CONTRACT_FORM_PLACE + '*'} />
        <DateInput name='date' value={model['date']} onChange={(date) => setVal('date', date)} validate={operatingCostValidators['date']} label={t.DIGITAL_CONTRACT_FORM_DATE + '*'} />
        <Form.Group className='actions text-center'>
          <div className='group'>
            <a
              href=''
              className='button primary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                onSubmit(e)
              }}
              style={{
                textDecoration: 'none',
                color: 'unset'
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_SEND}
            </a>
            <a
              href='/documents'
              className='button secondary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                DocumentStore.onCloseForm()
              }}
              style={{
                textDecoration: 'none',
                color: 'unset'
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_CANCEL}
            </a>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

OperatingCostsPrepayment.propTypes = {
  file: PropTypes.object.isRequired,
  contractStore: PropTypes.object
}

export default inject('contractStore')(observer(OperatingCostsPrepayment))
