import { api } from '../service/api.service'

export default class ProfileStore {
  async getProfile() {
    const res = await api.get('/user/profile')
    return res.data
  }

  async updateProfile(data) {
    const res = await api.put('/user/profile', data)
    return res.data
  }

  async deleteAccount() {
    const res = await api.delete('/user/delete-profile')
    return res.data
  }
}
