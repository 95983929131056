import React, { Component } from "react";
import propTypes from "prop-types";
import { observer, inject } from "mobx-react";
import withRouter from "../../components/withRouter";
import { Button } from "react-bootstrap";
import { Button as AndtButton } from "antd";
import { Checkbox } from "../../components/formComponents";
import t from "../../service/translate.service";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import { Alert, Modal } from "antd";
import {
  extractError,
  extractErrorFromSap,
} from "../../service/response.service";
import ContractVerificationPage from "./ContractVerificationPage";

class ContractOverviewCondo extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired,
    consumptionStore: propTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      contract: "",
      errorMsg: "",
      loading: true,
      isShowModal: false,
      modalContent: null,
      contractVerified: false,
      verificationStarted: false,
      verificationEnabled: false,
    };
  }

  async componentDidMount() {
    this.props.contractStore.clearError()

    await this?.props?.contractStore?.initContracts();
    const contractNumber =
      this?.props?.contractStore?.selectedContract?.contractNumber;

    try {
      const id = this.props.useParams.contractNumber;
      const selectedContract =
        this?.props?.contractStore?.activeContracts?.find(
          (c) => c.contractNumber === id
        );
      if (!selectedContract?.rentAccountBalance) {
        await this?.props?.contractStore?.fetchAccountBalance(selectedContract);
      }
      this.setState({ contract: selectedContract });
      this.contract = selectedContract;

      if(this?.props?.configStore?.verificationEnabled) {
        const res = await this?.props?.contractStore?.getContractVerificationInfo(
          contractNumber
        );
        if(res) this.setState({contractVerified: true})
        this.props.contractStore.getContractDetails(
          contractNumber
        );
      } else this.props.contractStore.getContractDetails(contractNumber)

      const overdueToApp =
        await this.props.consumptionStore.getConsumptionRegistrationInfo(
          contractNumber,
          "Overdue"
        );
      this.setState({ overdueToApp });
    } catch (err) {
      this.setState({
        loading: false,
        errorMsg:
          this.props.contractStore.error ||
          extractErrorFromSap(err) ||
          t[extractError(err)] ||
          t["ERROR"],
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  optIn = async () => {
    try {
      this.setState({
        loading: true,
        overdueToApp: !this.state.overdueToApp,
      });

      await this.props.consumptionStore.consumptionRegister(
        this.state.contract,
        !this.state.overdueToApp,
        "Overdue"
      );

      const response =
        await this.props.consumptionStore.getConsumptionRegistrationInfo(
          this.state.contract.contractNumber,
          "Overdue"
        );

      this.setState({ overdueToApp: response });
    } catch (err) {
      this.setState({
        loading: false,
        errorMsg:
          this.props.contractStore.error ||
          extractErrorFromSap(err) ||
          t[extractError(err)] ||
          t["ERROR"],
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  showModal = () => {
    this.modal.visible = true;
  };

  openBalanceHistory = (contractNumber) => {
    this.props.navigate(`/rent-account/${contractNumber}`);
  };

  openPrepayments = (contractNumber) => {
    this.props.navigate(`/contracts/prepayments/${contractNumber}`);
  };

  startVerificationProcess = () => {
    this.setState({ verificationStarted: true });
  };

  render() {
    const { loading, overdueToApp, contract, errorMsg } = this.state;
    const contractNumber =
      this?.props?.contractStore?.selectedContract?.contractNumber || "";

    if (loading || this.props.contractStore.loading) {
      return <LoadingPageOverlay />;
    }

    const {
      tenants,
      operationalCostPrepaymentEnabled,
      verificationStarted,
      userVerified,
      contractDetailsError,
      error,
      condoDetails,
    } = this.props.contractStore;

    if (contractDetailsError.length) {
      return (
        <Alert
          message={contractDetailsError}
          type="error"
          style={{ marginBottom: "12px", marginTop: "12px" }}
        />
      );
    }

    return (
      <>
        {this.state.isShowModal && (
          <Modal
            title={this.state.modalContent}
            open={true}
            closable={false}
            onOk={() => this.setState({ isShowModal: false })}
            style={{ marginBottom: "12px", marginTop: "12px" }}
            cancelButtonProps={{ style: { display: "none" } }}
          />
        )}

        {error && !error.includes("ZTEN") && (
          <Alert
            message={error}
            type="error"
            style={{ marginBottom: "12px", marginTop: "12px" }}
          />
        )}

        {this.state.verificationStarted && (
          <ContractVerificationPage
            configStore={this.props.configStore}
            isAddingContract={false}
            redirectUrl={`/contract-overview/${this.contract.contractNumber}`}
          />
        )}

        <div className="contract-list">
          {errorMsg && (
            <Alert
              message={errorMsg}
              type="error"
              style={{ marginBottom: "12px", marginTop: "12px" }}
            />
          )}
          <div>
            {!this.state.contractVerified &&
              this.props?.configStore?.verificationEnabled && (
                <AndtButton
                  onClick={() => {
                    this.startVerificationProcess();
                    this.props.contractStore.clearError();
                  }}
                  className="contractVerificationStatus"
                >
                  {t.CONTRACT_VERIFICATION_BTN}
                </AndtButton>
              )}

            <table style={{ fontSize: 20 }}>
              <thead>
                <tr className="contractOverviewth">
                  <th className="contractOverviewtd" style={{ width: "300px" }}>
                    {t.CONTRACT_OVERVIEW_DETAILS}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="contractOverviewtd">
                    {t.LOGIN_PAGE_TENANT_NUMBER}:
                  </td>
                  <td className="contractOverviewtd">{contractNumber}</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">
                    {t.CONDO_START_DATE}:
                  </td>
                  <td className="contractOverviewtd">
                    {condoDetails.contractStartDate}
                  </td>
                </tr>
                {!condoDetails.contractEndDate === 'Unlimited' && (
                  <tr>
                    <td className="contractOverviewtd">
                      {t.CONDO_END_DATE}:
                    </td>
                    <td className="contractOverviewtd">
                      {condoDetails.contractEndDate}
                    </td>
                </tr>
                )}
                <tr>
                  <td className="contractOverviewtd">{t.USER_ADDRESS}:</td>
                  <td className="contractOverviewtd">
                    {this?.contract?.address}
                  </td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">{t.CONDO_PARTNERS}:</td>
                  <td className="contractOverviewtd">
                    {condoDetails?.tenants.map((tenant, index) => (
                      <p
                        key={index}
                        className="contractOverviewtd"
                        style={{ paddingBottom: 0, marginBottom: "8px" }}
                      >
                        {tenant.firstName} {tenant.lastName}
                      </p>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>

            <table style={{ fontSize: 20, marginTop: 30 }}>
              <thead>
                <tr className="contractOverviewth">
                  <th className="contractOverviewtd" style={{ width: "340px" }}>
                    {t.CONDO_MY_FEES}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="contractOverviewtd">{t.CONDO_FEES_SHARED}:</td>
                  <td className="contractOverviewtd">{condoDetails.feesShared} €</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">
                    {t.CONDO_FEES_NON_SHARED}:
                  </td>
                  <td className="contractOverviewtd">{condoDetails.feesNonShared} €</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">
                    {t.CONDO_RESERVE_FUND}:
                  </td>
                  <td className="contractOverviewtd">{condoDetails.reserveFund} €</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">
                    {t.CONDO_MANAGEMENT_FEE}:
                  </td>
                  <td className="contractOverviewtd">{condoDetails.managementFee} €</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">{t.CONDO_TOTAL_COST}:</td>
                  <td className="contractOverviewtd">{condoDetails.total} €</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: 80 }} className="loyalty-upload">
            <Button
              className="button primary"
              onClick={() => this.openBalanceHistory(contractNumber)}
            >
              {`${t.CONDO_ACCOUNT_BALANCE}`}
            </Button>
            {this?.props?.configStore?.rentContractDocumentsEnabled && (
              <div className="text-center">
                <Button
                  onClick={() =>
                    this.props.navigate(
                      `/contract-documents/${contractNumber}`
                    )
                  }
                  className="button primary"
                >
                  {t.CONDO_CONTRACT_DOCUMENTS}
                </Button>
              </div>
            )}
            {this?.props?.configStore?.ocCalculationsEnabled && (
              <div className="text-center">
                <Button
                  onClick={() =>
                    this.props.navigate(`/financial-plans-and-statements/${contractNumber}`)
                  }
                  className="button primary"
                >
                  {t.CONDO_FINANCIAL_PLANS_AND_STATEMENTS}
                </Button>
              </div>
            )}

            <div>
              <Checkbox
                label={t.CONDO_DUE_DATE_REMINDERS}
                value={overdueToApp}
                onChange={this.optIn}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default inject(
  "contractStore",
  "consumptionStore",
  "configStore"
)(withRouter(observer(ContractOverviewCondo)));
