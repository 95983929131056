import React from 'react'
import PrimaryTitle from '../../../../components/PrimaryTitle'
import { inject, observer } from 'mobx-react'
import { Input, DateInput, CustomSelect } from '../../../../components/formComponents'
import { Form, Button } from 'react-bootstrap'
import DocumentStore from '../../../../store/documentForm.store'
import PropTypes from 'prop-types'
import t from '../../../../service/translate.service'
import ContractSelector from '../../../../components/contractSelector'

const KeepAnimals = (props) => {
  const { model, setVal, keepAnimalValidators } = DocumentStore
  const { file } = props
  const onSubmit = async (e) => {
    e.preventDefault()
    DocumentStore.submit(file.fileName, props.contractStore.selectedContract.contractNumber)
  }
  const getAttrs = field => {
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: keepAnimalValidators[field]
    }
  }
  return (
    <div className='form-holder'>
      <PrimaryTitle title={t.KEEP_ANIMALS} />
      <Form>
        <ContractSelector label padding page='documents' />
        <h3 className='inputs-text'>{t.KEEP_ANIMALS_HEAD_ONE}</h3>
        <Input name='keepAnimalSpecie' {...getAttrs('keepAnimalSpecie')} label={t.DIGITAL_CONTRACT_FORM_ANIMAL_SPECIES + '*'} />
        <Input name='keepAnimalRace' {...getAttrs('keepAnimalRace')} label={t.DIGITAL_CONTRACT_FORM_RACE + '*'} />
        <Input name='keepAnimalDescription' {...getAttrs('keepAnimalDescription')} label={t.DIGITAL_CONTRACT_DESCRIPTION_WEIGHT + '*'} />
        <p className='inputs-text'>{t.KEEP_ANIMALS_PARA_ONE}</p>
        <Input name='keepAnimalQuantity' {...getAttrs('keepAnimalQuantity')} label={t.DIGITAL_CONTRACT_DESCRIPTION_QUANTITY + '*'} />
        <CustomSelect styles={{ width: '100%', maxWidth: '150px' }} onChange={(option) => {
          setVal('keepAnimalOwnPet', option.value)
          setVal('keepAnimalType', '')
          setVal('keepAnimalNumber', '')
        }} options={[{ value: t['LOGOUT_YES'], label: t['LOGOUT_YES'] }, { value: t['LOGOUT_NO'], label: t['LOGOUT_NO'] }]} defaultValue={{ value: t['LOGOUT_NO'], label: t['LOGOUT_NO'] }} label={t.DIGITAL_CONTRACT_DESCRIPTION_ALREADY_OWN_PET + '*'} />
        {model['keepAnimalOwnPet'] === t['LOGOUT_YES'] && (
          <div className='more-inputs-holder'>
            <Input name='keepAnimalType' {...getAttrs('keepAnimalType')} label={t.DIGITAL_CONTRACT_ANIMAL_TYPE + '*'} />
            <Input name='keepAnimalNumber' {...getAttrs('keepAnimalNumber')} label={t.DIGITAL_CONTRACT_ANIMAL_NUMBER + '*'} />
          </div>
        )}
        <p className='input-para'>{t.KEEP_ANIMALS_PARA_TWO}</p>
        <Input name='place' {...getAttrs('place')} label={t.DIGITAL_CONTRACT_FORM_PLACE + '*'} />
        <DateInput name='date' value={model['date']} onChange={(date) => setVal('pate', date)} validate={keepAnimalValidators['date']} label={t.DIGITAL_CONTRACT_FORM_DATE + '*'} />
        <Form.Group className='actions text-center'>
          <div className='group'>
            <a
              href=''
              className='button primary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                onSubmit(e)
              }}
              style={{
                textDecoration: 'none',
                color: 'unset'
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_SEND}
            </a>
            <a
              href='/documents'
              className='button secondary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                DocumentStore.onCloseForm()
              }}
              style={{
                textDecoration: 'none',
                color: 'unset'
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_CANCEL}
            </a>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

KeepAnimals.propTypes = {
  file: PropTypes.object.isRequired,
  contractStore: PropTypes.object
}

export default inject('contractStore')(observer(KeepAnimals))
