import get from 'lodash/get'
import { api } from '../service/api.service'
import { extendObservable, action } from 'mobx'
import { extractResponse, extractError, extractErrorFromSap } from '../service/response.service'
import t from '../service/translate.service'
import UserStore from '../store/user.store'
import { buildValidators, required } from '../service/validate'
import moment from 'moment-timezone'

const CONSULTATION_ENDPOINT = `/consultation`
const SCHEDULE_ENDPOINT = `/schedule`

class ConsultationStore {
  constructor (contractStore) {
    extendObservable(this, {
      contractStore,
      loading: false,
      consultations: [],
      selectedConsultation: {},
      error: null,
      closestConsultation: null,
      submitted: false,
      success: false,
      isAppointmentRequested: '',
      event: {},
      calendarId: '',
      users: [],
      managers: []
    })
  
  }
  async getAll (selectedContractNumber) {
    if (selectedContractNumber) {
      this.contractStore.selectedContract = { contractNumber: selectedContractNumber }
      this.getUsersForContract(selectedContractNumber)
    }
    if (!this.contractStore.ready || !this.contractStore.selectedContract) {
      return
    }
    this.loading = true
    this.error = null
    const contractNumber = this.contractStore.selectedContract.contractNumber
    try {
      const response = await api.post(CONSULTATION_ENDPOINT + '/get-all', { contractNumber })
      const consultations = extractResponse(response)
      this.consultations = consultations
      if (consultations.length > 0) {
        await this.checkIsAppointmentRequested(contractNumber, consultations[0].calendarId)
      }
    } catch (err) {
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.consultations.clear()
    }

    this.loading = false
  }

  async bookAppointment (contractNumber, date, time, topic, description, location, calendarId) {
    const response = await api.post(`/consultation/${contractNumber}/book-event`, {
      date,
      time,
      topic,
      description,
      location,
      calendarId
    })
    return response
  }

  async getWeekSlots (contractNumber) {
    const response = await api.get(`/consultation/${contractNumber}/slots-week`)
    return response.data
  }

  async getDaySlots (contractNumber, date, startTime, endTime, calendarId) {
    const response = await api.get(`/consultation/${contractNumber}/slots-day?date=${date}&startTime=${startTime}&endTime=${endTime}&calendarId=${calendarId}`)
    return response.data
  }

  async checkIsAppointmentRequested (contractNumber, calendarId) {
    try {
      if (calendarId) {
        this.isAppointmentRequested = ''
        this.event = {}
        const appointments = await this.getEventsByContractNumber(contractNumber, calendarId)
        const event = appointments.filter((appointment) => moment(appointment.start.dateTime, 'YYYY-MM-DDTHH:mm:ss').diff(moment().tz('Europe/Berlin'), 'minutes') > 0)
        if (event[0]) {
          this.event = event[0]
          this.isAppointmentRequested = 'requested'
        } else {
          this.isAppointmentRequested = 'not_requested'
        }
        this.calendarId = calendarId
      } else {
        this.calendarId = ''
      }
    } catch (err) {
      this.calendarId = ''
    }
  }

  async getEventsByContractNumber (contractNumber, calendarId) {
    const response = await api.get(`/consultation/${contractNumber}/events?calendarId=${calendarId}&params=$filter=singleValueExtendedProperties/Any(ep: ep/id eq 'String {66f5a359-4659-4830-9070-00040ec6ac6e} Name ContractNumber' and ep/value eq '${contractNumber}')`)
    return response.data
  }

  async cancelEvent (contractNumber, eventId) {
    try {
      const response = await api.post(`/consultation/${contractNumber}/events/${eventId}`)
    } catch (err) {
      throw err
    }
  }

  async setSlotInProgress (contractNumber, calendarId, date, time) {
    const response = await api.post(`/consultation/${contractNumber}/set-slot-in-progress`, {
      calendarId,
      date,
      time
    })
    return response.data
  }

  async getSlotInProgress (contractNumber, calendarId, date, time) {
    const response = await api.get(`/consultation/${contractNumber}/get-slot-in-progress?calendarId=${calendarId}&date=${date}&time=${time}`)
    return response.data
  }

  async removeSlotInProgress (contractNumber, calendarId, date, time) {
    const response = await api.post(`/consultation/${contractNumber}/remove-slot-in-progress`, {
      calendarId,
      date,
      time
    })
    return response.data
  }

  async getPropertyManagers (selectedContractNumber) {

    if (selectedContractNumber) {
      this.contractStore.selectedContract = { contractNumber: selectedContractNumber }
      this.getUsersForContract(selectedContractNumber)
    }
    if (!this.contractStore.ready || !this.contractStore.selectedContract) {
      return
    }
    this.loading = true
    this.error = null
    const contractNumber = this.contractStore.selectedContract.contractNumber
    try {
      const response = await api.get(`/contract/${contractNumber}/property-managers`)
      this.managers = extractResponse(response)
    } catch (err) {
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.managers.clear()
    }

    this.loading = false
  }

  get validators () {
    return buildValidators(
      {
        subject: [required],
        timeslot: [required]
      },
      this.submitted
    )
  }

  handleMultipaneListItemClick = consultation => {
    this.selectedConsultation = consultation
  }

  async scheduleConsultation (schedule, subject, userEmail = UserStore.user.email, userPhone = UserStore.user.phone) {
    this.loading = true
    try {
      const response = await api
        .post(SCHEDULE_ENDPOINT, {
          contractNumber: this.contractStore.selectedContract.contractNumber,
          date: schedule.date,
          time: schedule.startTime + ':00',
          subject,
          employees: schedule.employees,
          location: schedule.location,
          userEmail,
          userPhone,
          userId: schedule.userId
        })
      this.submitted = false
      this.loading = false
      this.success = true
      schedule.contractNumber = response.contractNumber
      schedule.userId = response.userId
      this.getAll()
    } catch (err) {
      let error = t[extractError(err)] || extractError(err)
      const data = get(err, 'response.data.data')
      if (data) {
        error = `${error} ${data}`
      }
      this.error = error
      this.loading = false
    }
  }

  unScheduleConsultation = action((scheduleId, schedule) => {
    return api
      .delete(SCHEDULE_ENDPOINT + '/' + scheduleId, { data: {
        employees: schedule.employees
      } })
      .then(response => {
        const responseData = extractResponse(response)
        schedule.contractNumber = response.contractNumber
        schedule.userId = response.userId
        this.getAll()
        return responseData
      })
      .catch(err => {
        const msg = extractError(
          err,
          'Error occured when trying to schedule consultation.'
        )
        this.loginError = msg
        throw msg
      })
  })

  expandConsultation = (location, consultationDate, consultationTimeFrom) => {
    this.consultations = this.consultations.map(c => {
      if (c.location === location &&
        c.date === consultationDate &&
        c.timeFrom === consultationTimeFrom) {
        c.expanded = true
      } else {
        c.expanded = false
      }
      return c
    })
  }

  async getUsersForContract (selectedContract) {
    try {
      const res = await api.get('/user/contract/' + selectedContract)
      this.users = res.data
    } catch (err) {
      throw err
    }
  }
}

export default ConsultationStore
