import { api } from "../service/api.service";

class AdventStore {
  async getAll() {
    const res = await api.get("/advent")
    return res?.data
  }
}

export default new AdventStore();
