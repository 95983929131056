import React from 'react'
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'
import Welcome from './pages/Welcome'

// Pages
import App from './App'
import NewsletterSubscribeConfirm from './pages/Profile/NewsletterSubscribeConfirm'
import { ViewProfile } from './pages/Profile'
import { FeedbackPage } from './pages/Feedback'
import NotFoundPage from './pages/NotFoundPage'
import { RepairsAndInquiries, RepairsAndInquiriesNewRequest } from './pages/RepairsAndInquiries'
import OfferItem from './pages/OfferItem'
import { Documents } from './pages/Documents'
import DocumentForm from './pages/Documents/DocumentForm/DocumentForm'
import {ReceiptInspection, ReceiptInspectionPreview} from "./pages/ReceiptInspection";
import RegistrationThankYou from './components/RegistrationThankYou'
import { AddContract, ContractsPage, Prepayments } from './pages/Contracts'
import  Letters from './pages/Letters'
import Onboarding from './pages/Onboarding'
import TenantServices from './pages/TenantServices'
import { Benefits, Offers } from './pages/Benefits'
import { InboxPage } from './pages/Inbox'
import ImprintPage from './pages/ImprintPage'
import TermsAndConditionsPage from './pages/TermsAndConditionsPage'
import { LoyaltyPage, LoyaltyFaq } from './pages/Loyalty'
import { FaqCategories, FaqList } from './pages/faq'
import HeatingAndEnergy from './pages/HeatingAndEnergy/HeatingAndEnergy'

// Stores
import ServerErrorPage from './pages/ServerErrorPage'
import withRouter from './components/withRouter'
import RentAccountHistory from './pages/Contracts/RentAccountHistory'
import ContractOverview from './pages/Contracts/ContractOverview'
import OcCalculations from "./pages/Contracts/OcCalculations";
import OcCalculationsPreview from "./pages/Contracts/OcCalculationsPreview";
import FinancialPlansAndStatements from './pages/Contracts/FinancialPlansAndStatements'
import FinancialPlansAndStatementsPreview from './pages/Contracts/FinancialPlansAndStatementsPreview'
import RentContractDocuments from './pages/RentContractDocuments/RentContractDocuments'
import RentContractDocumentsPreview from './pages/RentContractDocuments/RentContractDocumentsPreview'
import ViewDocument from './pages/Documents/ViewDocument'
import { AuthenticatedTemplate } from '@azure/msal-react'
import LoginPage from './pages/LoginPage'
import RegistrationPage from './pages/RegistrationPage'
import ConfirmRegistration from './pages/Profile/ConfirmRegistration'
import DeleteProfile from './pages/Profile/DeleteProfile'
import ServicePointsPage from './pages/Consultations/ServicePointsPage/ServicePointsPage'
import { ConsultationSlotsPage } from './pages/Consultations'
import ContractVerificationHandler from './pages/Contracts/ContractVerificationHandlerPage'
import MyPaymentDetails from './pages/Contracts/MyPaymentDetails'
import ContractOverviewCondo from './pages/Contracts/ContractOverviewCondo'
import ForgotPassword from './pages/Password/ForgotPassword'
import ContractDocuments from './pages/ContractDocuments/ContractDocuments'
import ContractDocumentsPreview from './pages/ContractDocuments/ContractDocumentsPreview'

const ProtectedRoute = ({ element: Component, ...props }) => {
  return (
    <AuthenticatedTemplate>
      <Outlet />
    </AuthenticatedTemplate>
  )
}

export default () => {
  const RepairsAndInquiriesWithRouter = withRouter(RepairsAndInquiries)

  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route element={<ProtectedRoute />}>
            <Route index element={<Welcome />} />
            <Route path='profile' element={<ViewProfile />} />
            <Route path='repairs-and-inquiries/new-request' element={<RepairsAndInquiriesNewRequest />} />
            <Route path='repairs-and-inquiries' element={<RepairsAndInquiriesWithRouter />} />
            <Route path='feedback/:rating' element={<FeedbackPage />} />
            <Route path='offer/:id' element={<OfferItem />} />
            <Route path='offers' element={<Offers />} />
            <Route path='consultations' element={<ServicePointsPage />} />
            <Route path='consultations/:contractNumber/slots' element={<ConsultationSlotsPage />} />
            <Route path='documents' element={<Documents />} />
            <Route path='documents/preview/:name' element={<ViewDocument />} />
            <Route path='profile/delete-account' element={<DeleteProfile />} />
            <Route path='documents/sign/:name' element={<DocumentForm />} />
            <Route path='heating-and-energy' element={<HeatingAndEnergy />} />
            <Route path='tenant-services' element={<TenantServices />} />
            <Route path='benefits' element={<Benefits />} />
            <Route path='faq' element={<FaqCategories />} />
            <Route path='faq/:id' element={<FaqList />} />
            <Route path='onboarding' element={<Onboarding />} />
            <Route path='onboarding/add-contract' element={<AddContract onboarding />} onboarding />
            <Route path='contracts/email-verification' element={<ContractVerificationHandler />} />
            <Route path='inbox' element={<InboxPage />} />
            <Route path='imprint' element={<ImprintPage />} />
            <Route path='terms-and-conditions' element={<TermsAndConditionsPage />} />
            <Route path='loyalty-program/faq' element={<LoyaltyFaq />} />
            <Route path='loyalty-program' element={<LoyaltyPage />} />
            <Route path='contracts/prepayments/:contractNumber' element={<Prepayments />} />
            <Route path='contracts/add-contract' element={<AddContract />} />
            <Route path='contracts' element={<ContractsPage />} />
            <Route path='letters' element={<Letters />} />
            <Route path='rent-account/:contractNumber' element={<RentAccountHistory />} />
            <Route path='contract-overview/:contractNumber' element={<ContractOverview />} />
            <Route path='contract-overview-condo/:contractNumber' element={<ContractOverviewCondo />} />
            <Route path='receipt-inspection' element={<ReceiptInspection/>} />
            <Route path='receipt-inspection/:contractNumber/:caseNumber/:year/:docName/:docId/preview' element={<ReceiptInspectionPreview/>} />
            <Route path='rent-contract-documents/:contractNumber' element={<RentContractDocuments />} />
            <Route path='my-payment-details/:contractNumber' element={<MyPaymentDetails />} />
            <Route path='rent-contract-documents/:contractNumber/:docId/preview' element={<RentContractDocumentsPreview/>} />
            <Route path='contract-documents/:contractNumber' element={<ContractDocuments />} />
            <Route path='contract-documents/:contractNumber/:docId/preview' element={<ContractDocumentsPreview/>} />
            <Route path='oc-calculations/:contractNumber' element={<OcCalculations />} />
            <Route path='oc-calculations/:contractNumber/preview/:docId' element={<OcCalculationsPreview />} />
            <Route path='financial-plans-and-statements/:contractNumber' element={<FinancialPlansAndStatements />} />
            <Route path='financial-plans-and-statements/:contractNumber/preview/:docId' element={<OcCalculationsPreview />} />
            <Route path='register-success' element={<FinancialPlansAndStatementsPreview />} />
          </Route>

          <Route path='confirm-newsletter-subscription/:id' element={<NewsletterSubscribeConfirm />} />
          <Route path='confirm-registration' element={<ConfirmRegistration />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='error' element={<ServerErrorPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='register' element={<RegistrationPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Router>
  )
}
