import { makeObservable, observable  } from "mobx";
import {api} from "../service/api.service";

class RentContractDocumentStore {
  loading = false;
  documents = [];
  documentsCount = 0;
  document = undefined;
  preview = false;

  constructor() {
    makeObservable(this, {
      documents: observable,
      loading: observable,
      documentsCount: observable,
      preview: observable
    });
  }

  async getDocuments(contractNumber) {
    this.loading = true;
    const res = await api.get(`/contract/${contractNumber}/documents/ZRENTAL_DOCS`);
    this.documents = res?.data || [];
    this.loading = false;
  }

  async getDocument(contractNumber, id, docName) {
    this.loading = true;

    // if client is refreshed 
    if (!this.documents.length) await this.getDocuments(contractNumber) 
    const doc = this.documents.find(d => d.id === id)

    docName = docName || doc?.name

    if (docName === 'Mietbescheinigung') {
      return this.getRentCertificatePDF(contractNumber, 'RC', id)
    }
    if (docName === 'Mietschuldenfreiheitsbescheinigung') {
      return this.getRentCertificatePDF(contractNumber, 'RPC', id)
    }
    
    const res = await api.get(`/contract/${contractNumber}/documents/ZRENTAL_DOCS/${id}`, {
      responseType: "blob"
    });
    this.document = res.data;
    this.loading = false;
    return res.data
  }

  async getRentCertificatePDF(contractNumber, type, id) {
    this.loading = true;
    const res = await api.get(`/contract/${contractNumber}/certificate/${type}/pdf?id=${id}`, {
      responseType: "blob"
    })
    this.document = res.data;
    this.loading = false;
    return res.data
  }
}

const rentContractDocumentStore = new RentContractDocumentStore();

export default rentContractDocumentStore;