import React from 'react'
import { Link } from 'react-router-dom'
import t from '../service/translate.service'

const LoginRegisterSwitcher = () => {
  const pages = [
    {
      name: t.LOGIN_PAGE_REGISTRATION,
      link: '/login'
    },
    {
      name: t.LOGIN_PAGE_REGISTER,
      link: '/register'
    }
  ]

  return (
    <div className='login-register-switcher'>
      {pages.map(page => {
        const activeClass = window.location.pathname.includes(page.link) ? 'active' : ''
        return (
          <Link className={activeClass} key={page.link} to={page.link}>
            {page.name}
          </Link>
        )
      })}
    </div>
  )
}

export default LoginRegisterSwitcher
