import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import t from '../service/translate.service'
import PrimaryTitle from '../components/PrimaryTitle'
import PropTypes from 'prop-types'
import withRouter from '../components/withRouter'
import TenantServicesList from '../components/TenantServicesList'
import { extendObservable } from 'mobx'
import RepairsAndIquiriesStore from '../store/repairsAndInquiries.store'

class TenantServices extends Component {
  constructor(props) {
    super(props);
    extendObservable(this, {
      User: this.props.userStore
    });
    this.store = new RepairsAndIquiriesStore();
    this.store.getReports();
  }
  onClick = (link) => {
    this.props.menuStore.setCurrentPage(link)
    this.props.navigate(link)
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <PrimaryTitle title={t.TENANT_SERVICES} />

        <TenantServicesList
          menuStore={this.props.menuStore}
          configStore={this.props.configStore}
          onClick={this.onClick}
          User={this.User}
        />

      </div>
    )
  }
}
TenantServices.propTypes = {
  menuStore: PropTypes.object.isRequired,
}
export default inject('menuStore', 'userStore', 'configStore')(withRouter(observer(TenantServices)))
