import React from 'react'
import moment from 'moment'
import iconsArrowRight from '../../assets/svg/iconsArrowRight.svg'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'

const RepairsAndInquiriesItems = props => {
  const { items, selectedItem, handleClick, itemContentComponent, actions } = props
  return (
    <div>
      <div>
        <div className='list'>
          {items.map(item => {
            const activeClass = item.code === selectedItem.code ? 'active' : ''
            const statusClass = item.repairAndInquiryStatus.name.toLowerCase().replace(' ', '-')
            return (
              <div
                key={item.code}
                className='item'
              >
                <div className={`container ${activeClass}`}>
                  <a
                    href=''
                    className='summary'
                    onClick={e => {
                      if (e.key !== 'Tab') {
                        e.preventDefault()
                      }
                      handleClick(item.code)
                    }}
                    style={{
                      textDecoration: 'none',
                      color: 'unset'
                    }}
                  >
                    <div className='group'>
                      <div className='group'>
                        <div className={`status ${statusClass}`}>
                          {localStorage.getItem('locale') === 'en' ? item.repairAndInquiryStatus.name : item.repairAndInquiryStatus.nameDe}
                        </div>
                        <div className='service-request-id'>
                          {item.code}
                        </div>
                        {item.urgency === 'Urgent' && (
                          <div className='urgent'>
                            {item.urgency === 'Urgent' ? t.URGENT : ''}
                          </div>
                        )}
                      </div>
                      <p>{item.subject}</p>
                      <div className='date'>
                        {t.SUBMITTED_ON + ' '} {moment(item.create_date).format('D.M.Y')}
                      </div>
                    </div>
                    <div className='icon next'>
                      <img src={iconsArrowRight} />
                    </div>
                  </a>
                  {itemContentComponent &&
                    React.cloneElement(itemContentComponent, { item })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Actions actions={actions} />
    </div>
  )
}

const Actions = props => {
  const { actions } = props
  return (
    <div className='actions'>
      {actions.map((action, index) => {
        const classes = action.classes
        return (
          <a
            href=''
            key={index}
            className={`button ${classes}`}
            onClick={e => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
              action.onClick()
            }}
            style={{
              textDecoration: 'none',
              color: 'unset',
              marginTop: '26px'
            }}
          >
            {action.text}
          </a>
        )
      })}
    </div>
  )
}
Actions.propTypes = {
  actions: PropTypes.array
}
RepairsAndInquiriesItems.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.object,
  handleClick: PropTypes.func,
  itemContentComponent: PropTypes.object,
  actions: PropTypes.array
}
export default RepairsAndInquiriesItems
