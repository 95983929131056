import t from './service/translate.service'
import { ConsultationsImage,
  DocumentsImage,
  ContractsImage,
  RepairsAndInquiriesImage,
  LoyaltyImage,
  BenefitsImage,
  BenefitsIcon,
  HomeIcon,
  ProfileIcon,
  InboxIcon,
  ServicesIcon,
  LogoutIcon,
  FaqImage, 
  FaqIcon, 
  EnergyConsumptionIcon,
  LettersIcon,
  OcInspectionIcon
} from './components/icons'

export const menuItems = [
  {
    path: '/',
    name: t.HOME,
    svg: HomeIcon,
    submenu: []
  },
  {
    path: '/benefits',
    name: t.BENEFITS,
    svg: BenefitsIcon,
    submenu: [],
    isCondominium: true
  },
  {
    path: '/tenant-services',
    name: t.TENANT_SERVICES,
    svg: ServicesIcon,
    submenu: [
      {
        path: '/loyalty-program',
        name: t.LOYALTY_PROGRAM,
        isCondominium: true
      },
      {
        path: '/repairs-and-inquiries',
        name: t.REPAIRS_AND_INQUIRIES
      },
      {
        path: '/documents',
        name: t.DOCUMENTS,
        isCondominium: true
      },
      {
        path: '/consultations',
        name: t.MENU_CONSULTATIONS,
        isCondominium: true
      },
      {
        path: '/heating-and-energy',
        name: t.HEATING_AND_ENERGY_TITLE
      },
      {
        path: '/contracts',
        name: t.CONTRACTS
      },
      {
        path: '/receipt-inspection',
        name: t.RECEIPT_INSPECTION
      }
    ]
  },
  {
    path: '/inbox',
    name: t.INBOX,
    svg: InboxIcon,
    submenu: []
  },
  {
    path: '/profile',
    name: t.PROFILE,
    svg: ProfileIcon,
    submenu: []
  },
  {
    path: '/faq',
    name: t.MAIN_FAQ,
    svg: FaqIcon,
    submenu: [],
    isCondominium: true
  },
  {
    path: '#',
    name: t.LOGOUT,
    svg: LogoutIcon,
    submenu: []
  }
]

export const documents = [
  {
    fileName: 'DE_BK-VZ_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_1,
    sizeInKb: 122,
    isForm: true
  },
  {
    fileName: 'DE_weiterer Vertragspartner_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_9,
    sizeInKb: 116,
    isForm: true
  },
  {
    fileName: 'DE_Datenfreigabe_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_2,
    sizeInKb: 113,
    isForm: true
  },
  {
    fileName: 'DE_Einbauten_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_3,
    sizeInKb: 118,
    isForm: true
  },
  {
    fileName: 'DE_Guthaben_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_4,
    sizeInKb: 113,
    isForm: true
  },
  {
    fileName: 'DE_Kaution_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_5,
    sizeInKb: 123,
    isForm: true
  },
  {
    fileName: 'DE_Namensanderung_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_6,
    sizeInKb: 113,
    isForm: true
  },
  {
    fileName: 'DE_Schlussel_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_7,
    sizeInKb: 115,
    isForm: true
  },
  {
    fileName: 'DE_Tierhaltung_v02.pdf',
    label: t.FILE_PAGES_DOWNLOAD_ITEM_8,
    sizeInKb: 117,
    isForm: true
  }
]

export const quickLinksHome = [
  {
    image: LoyaltyImage,
    name: t.LOYALTY_PROGRAM,
    link: '/loyalty-program',
    isCondominium: true
  },
  {
    image: EnergyConsumptionIcon,
    name: t.HEATING_AND_ENERGY_TITLE,
    link: '/heating-and-energy'
  },
  {
    image: RepairsAndInquiriesImage,
    name: t.REPAIRS_AND_INQUIRIES,
    link: '/repairs-and-inquiries'
  },
  {
    image: LettersIcon,
    name: t.MENU_LETTERS,
    link: '/letters',
    isCondominium: true
  },
  {
    image: OcInspectionIcon,
    name: t.RECEIPT_INSPECTION,
    link: '/receipt-inspection',
  },
  {
    image: BenefitsImage,
    name: t.BENEFITS,
    link: '/benefits',
    isCondominium: true
  },
  {
    image: DocumentsImage,
    name: t.DOCUMENTS,
    link: '/documents',
    isCondominium: true
  },
  {
    image: ConsultationsImage,
    name: t.MENU_CONSULTATIONS,
    link: '/consultations',
    isCondominium: true
  },
  {
    image: FaqImage,
    name: t.MAIN_FAQ,
    link: '/faq',
    isCondominium: true
  }
]

export const quickLinksServices = [
  {
    image: LoyaltyImage,
    name: t.LOYALTY_PROGRAM,
    link: '/loyalty-program',
    isCondominium: true
  },
  {
    image: EnergyConsumptionIcon,
    name: t.HEATING_AND_ENERGY_TITLE,
    link: '/heating-and-energy'
  },
  {
    image: RepairsAndInquiriesImage,
    name: t.REPAIRS_AND_INQUIRIES,
    link: '/repairs-and-inquiries'
  },
  {
    image: ContractsImage,
    name: t.CONTRACTS,
    link: '/contracts'
  },
  {
    image: LettersIcon,
    name: t.MENU_LETTERS,
    link: '/letters',
    isCondominium: true
  },
  {
    image: DocumentsImage,
    name: t.DOCUMENTS,
    link: '/documents',
    isCondominium: true
  },
  {
    image: OcInspectionIcon,
    name: t.RECEIPT_INSPECTION,
    link: '/receipt-inspection',
  },
  {
    image: ConsultationsImage,
    name: t.MENU_CONSULTATIONS,
    link: '/consultations',
    isCondominium: true
  },
  {
    image: FaqImage,
    name: t.MAIN_FAQ,
    link: '/faq',
    isCondominium: true
  }
]

export const availableLinks = [
  {
    id: 1,
    type: 'contract',
    name: 'Contract',
    nameDe: 'Vertrag',
    to: '/contracts'
  },
  {
    id: 2,
    type: 'chat',
    name: 'Chat',
    nameDe: 'Chat'
  },
  {
    id: 3,
    type: 'loyalty_program',
    name: 'Loyalty program',
    nameDe: 'Treueprogramm',
    to: '/loyalty-program'
  },
  {
    id: 4,
    type: 'top_deals',
    name: 'Top-Deals',
    nameDe: 'Top-Deals',
    to: '/benefits'
  },
  {
    id: 5,
    type: 'repairs_and_inquiries',
    name: 'Repairs & Inquiries',
    nameDe: 'Reparaturen & Anfragen',
    to: '/repairs-and-inquiries'
  },
  {
    id: 6,
    type: 'consultations',
    name: 'Consultations',
    nameDe: 'Mietersprechstunden',
    to: '/consultations'
  },
  {
    id: 7,
    type: 'phone_numbers',
    name: 'Phone numbers',
    nameDe: 'Telefonnummern'
  },
  {
    id: 8,
    type: 'apartment_search',
    name: 'Apartment search',
    nameDe: 'Wohnungssuche'
  }]
export const inboxCategories = [{
  type: 'contract',
  name: t.CONTRACTS
},
{
  type: 'damage_report',
  name: t.REPAIRS_AND_INQUIRIES
},
{
  type: 'scheduled_consultation',
  name: t.SCHEDULED_CONSULTATIONS_TITLE
},
{
  type: 'general',
  name: t.ALERTS_GENERAL_TITLE
},
{
  type: 'oc_inspection',
  name: t.ALERTS_OC_INSPECTION_TITLE
},
{
  type: 'letter',
  name: t.ALERTS_LETTER_TITLE
},
{
  type: 'certificate',
  name: t.ALERTS_CERTIFICATE_TITLE
},
{
  type: 'emailVerification',
  name: 'Email Verification'
}]
