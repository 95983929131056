import React, { Component } from 'react'
import iconsFaqArrow from '../../assets/svg/iconsFaqArrow.svg'
import PrimaryTitle from '../../components/PrimaryTitle'
import t from '../../service/translate.service'
import Collapsible from 'react-collapsible'

class LoyaltyFaq extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <div className='faq-wrap'>
        <PrimaryTitle title='FAQ' />

        <div className='loyalty-subtitle text-center'>{t.LOYALTY_FAQ_SUBTITLE}</div>

        <Collapsible trigger={(
          <a
            href=''
            onClick={(e) => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
            }}
            style={{
              textDecoration: 'none',
              color: 'unset'
            }}
          >
            <span>{t.FAQ_Q_1}</span>
            <div className='arrow'><img src={iconsFaqArrow} /></div>
          </a>)}>
          <p>{t.FAQ_A_1}</p>
        </Collapsible>

        <Collapsible trigger={(
          <a
            href=''
            onClick={(e) => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
            }}
            style={{
              textDecoration: 'none',
              color: 'unset'
            }}
          >
            <span>{t.FAQ_Q_2}</span>
            <div className='arrow'><img src={iconsFaqArrow} /></div>
          </a>
        )}>
          <p>{t.FAQ_A_2}</p>
        </Collapsible>

        <Collapsible trigger={(
          <a
            href=''
            onClick={(e) => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
            }}
            style={{
              textDecoration: 'none',
              color: 'unset'
            }}
          >
            <span>{t.FAQ_Q_3}</span>
            <div className='arrow'><img src={iconsFaqArrow} /></div>
          </a>)}>
          <p>{t.FAQ_A_3}</p>
        </Collapsible>

        <Collapsible trigger={(
          <a
            href=''
            onClick={(e) => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
            }}
            style={{
              textDecoration: 'none',
              color: 'unset'
            }}
          >
            <span>{t.FAQ_Q_4}</span>
            <div className='arrow'><img src={iconsFaqArrow} /></div>
          </a>
        )}>
          <p>{t.FAQ_A_4}</p>
        </Collapsible>
        <div className='loyalty-subtitle text-center'>{t.LOYALTY_FAQ_TEXT}</div>
      </div>
    )
  }
}

export default LoyaltyFaq
