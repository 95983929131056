import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import t from '../../service/translate.service'
import ProfileStore from '../../store/profile.store'
import PrimaryTitle from '../../components/PrimaryTitle'
import ListSavedFields from '../../components/ListSavedFields'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import historyService from '../../service/history.service'
import { useMsal } from '@azure/msal-react'
import { b2cPolicies } from '../../azureB2C/authConfig'
import { Alert } from 'antd'
import { extractError } from '../../service/response.service'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import { Checkbox } from '../../components/formComponents'

const ViewProfile = (props) => {
  const profileStore = new ProfileStore()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showNewsletterModal, setShowNewsletterModal] = useState(false)
  const [profile, setProfile] = useState()
  const { instance, accounts } = useMsal()
  const isSocialLogin = localStorage.getItem('socialLogin')

  const language = localStorage.getItem('locale') || 'de'
  useEffect(() => {
    (async () => {
      setLoading(true)
      const timeout = localStorage.getItem('profile_edited') === '1' ? 10000 : 0
      setTimeout(async () => {
        await profileStore.getProfile()
        .then(res => {
          setProfile(res)
        })
        .catch((err) => {
          setError(t[extractError(err)] || t['ERROR'])
        })
        localStorage.removeItem('profile_edited')
        setLoading(false)
      }, timeout)
    })()
  }, [])

  const onNewsletterChange = async () => {
    setShowNewsletterModal(false)
    setLoading(true)
    setError('')
    setSuccess('')
    try {
      const newsletterSubscription = !profile?.subscribed
      await profileStore.updateProfile({ subscribed: newsletterSubscription })
      if (!newsletterSubscription) {
        setSuccess(t.NEWSLETER_SUCCESS_UNREGISTRATION_TEXT)
        setProfile({
          ...profile,
          subscribed: false
        })
      } else {
        setSuccess(t.NEWSLETER_SUCCESS_REGISTRATION_TEXT)
      }
    } catch (err) {
      console.log(err)
      setError(t[extractError(err)] || t['ERROR'])
    }
    setLoading(false)
  }

  const handleEditPersonalData = () => {
    instance.acquireTokenRedirect({
      ...b2cPolicies.authorities.editProfile,
      account: accounts[0],
      scopes: [process.env.REACT_APP_AZ_CLIENT_ID || ''],
      extraQueryParameters: {'ui_locales': `${language}`}
    })
  }

  const handleEditEmail = () => {
    instance.acquireTokenRedirect({
      ...b2cPolicies.authorities.changeEmail,
      account: accounts[0],
      scopes: [process.env.REACT_APP_AZ_CLIENT_ID || ''],
      extraQueryParameters: {'ui_locales': `${language}`}
    })
  }

  const handleEditPassword = () => {
    instance.acquireTokenRedirect({
      ...b2cPolicies.authorities.changePassword,
      account: accounts[0],
      scopes: [process.env.REACT_APP_AZ_CLIENT_ID || ''],
      extraQueryParameters: {'ui_locales': `${language}`}
    })
  }

  const fields = [
    {
      label: t.USER_FIRST_NAME,
      value: profile?.firstName || ''
    },
    {
      label: t.USER_LAST_NAME,
      value: profile?.lastName || ''
    },
    {
      label: t.USER_EMAIL,
      value: profile?.email || ''
    },
    {
      label: t.USER_PHONE,
      value: profile?.phone || ''
    },
    {
      label: t.USER_MOBILE,
      value: profile?.mobile || ''
    }
  ]

  if (showNewsletterModal) {
    return (
      <ThankYouOverlay
        title={profile?.subscribed ? t.UNREGISTER_TITLE : t.REGISTER}
        description={profile?.subscribed ? t.NEWSLETER_UNREGISTRATION_TEXT : t.NEWSLETER_REGISTRATION_TEXT}
        buttonText={t.NEWSLETTER_REGISTRATION_YES}
        buttonLink='/profile'
        handleClick={onNewsletterChange}
        cancelButtonText={t.NEWSLETTER_REGISTRATION_NO}
        handleCancel={() => setShowNewsletterModal(false)}
      />
    )
  }

  return (
    <div>
      {loading && <LoadingPageOverlay />}
      <PrimaryTitle title={t.PROFILE_TITLE} />
      {error && (
        <Alert message={error} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
      )}
      {success && (
        <Alert message={success} type='success' style={{marginBottom: '12px', marginTop: '12px'}} />
      )}
      <div className='view-saved-profile-data'>
        <ListSavedFields fields={fields} textCenter />
      </div>
      <div
        style={{
          marginTop: '32px',
          marginBottom: '44px'
        }}
      >
        <Checkbox
          label={t['USER_WANT_NEWSLETTER']}
          value={profile?.subscribed || false}
          onChange={e => setShowNewsletterModal(true)}
        />
        <p className='newsletter-sub'>{t.NEWSLETTER_POLICY_TEXT_1}<a href='https://www.grandcityproperty.de/datenschutz' rel="nofollow noreferrer noopener" target='_blank'>{t.NEWSLETTER_POLICY_LINK}</a>{t.NEWSLETTER_POLICY_TEXT_2}</p>
      </div>
      <div className='text-center'>
        <Link
          className='button primary'
          onClick={handleEditPersonalData}
        >
          {t.PROFILE_EDIT}
        </Link>
      </div>
      {!isSocialLogin && (
        <div className='text-center' style={{ margin: 20 }}>
          <Link
            className='button primary'
            onClick={handleEditEmail}
          >
            {t.EMAIL_EDIT}
          </Link>
        </div>
      )}
      {!isSocialLogin && (
        <div className='text-center' style={{ margin: 20 }}>
          <Link
            className='button primary editPassword'
            onClick={handleEditPassword}
          >
            {t.PASSWORD_EDIT}
          </Link>
        </div>
      )}
      <div className='text-center' style={{ margin: 20}}>
        <Link to='/profile/delete-account'
          className='button primary editPassword'
          onClick={() => historyService.push('/profile/delete-account')}>
            {t.DELETE_ACCOUNT}
          </Link>
      </div>
    </div>
  )
}

export default observer(ViewProfile)
