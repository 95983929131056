import React from 'react'
import t from '../../service/translate.service'
import moment from 'moment'
import PropTypes from 'prop-types'
import { api } from '../../service/api.service'

const BenefitList = ({ items, openLink }) => {
  return (
    <div className='benefit-list'>
      {items.map(item => (
        <a
          href=''
          key={item.id}
          className='item'
          onClick={async (e) => {
            if (e.key !== 'Tab') {
              e.preventDefault()
            }
            await api.get(`/partner/analytics/${item.id}`).catch((err) => {
              console.log(err)
            })
            openLink(e, item.url)
          }}
          style={{
            textDecoration: 'none',
            color: 'unset'
          }}
        >
          <div className='container'>
            <div>
              <div className='image'>
                <img src={item.image} />
              </div>
              <div className='group'>
                <div className='title'>{item.name}</div>
                <div className='description'>{localStorage.getItem('locale') === 'en' ? item.title : item.titleDe}</div>
                <div className='expires'>{item.expiryDate && t.BENEFIT_VALID_UNTIL} {item.expiryDate && moment(item.expiryDate).format('DD.MM.YYYY')}</div>
              </div>
            </div>
            <hr />
          </div>
        </a>
      ))}
    </div>
  )
}

BenefitList.propTypes = {
  items: PropTypes.array,
  openLink: PropTypes.func
}
export default BenefitList
