import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import LoadingPageOverlay from "../../../components/LoadingPageOverlay";
import ContractSelector from "../../../components/contractSelector";
import withRouter from "../../../components/withRouter";
import { OfficeWorkingHours } from "./components/OfficeWorkingHours";
import t from "../../../service/translate.service";
import { Alert } from "antd";
import { Booking } from "./components/Booking";
import { extendObservable } from "mobx";
import { find } from "lodash";
import User from '../../../store/user.store'
import NoContent from "../../../components/NoContent";
import { ContractsImage } from "../../../components/icons";

class ServicePointsPage extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.consultationStore;
    this.contractStore = this.store.contractStore;
    this.navigate = this.props.navigate;
    extendObservable(this, {
      hasActiveContracts: false
    })
  }

 componentDidMount() {
  const hasActiveContracts = Boolean(find(User.user?.contracts, (c) => c.valid && c.active));
  this.hasActiveContracts = hasActiveContracts
  if(hasActiveContracts) {
    this.store.getAll();
    this.store.getPropertyManagers();
  }
 }

  cancelAppointment = async (contractNumber, eventId) => {
    try {
        this.store.error = null
        this.store.loading = true
        await this.store.cancelEvent(contractNumber, eventId)
        this.store.isAppointmentRequested = 'not_requested'
        this.store.loading = false
    } catch (err) {
        this.store.loading = false
        this.store.error = t['ERROR']
    }
  }

  render() {
    if(!this.hasActiveContracts){
      return (
        <NoContent message={t.NO_CONTRACTS} image={ContractsImage} />
      )
    }
    return (
      <div>
        {this.store.loading && <LoadingPageOverlay />}
        <ContractSelector
          getConsultations={() => {
            this.store.success = false;
            this.store.getAll();
            this.store.getPropertyManagers();
          }}
        />
        <div style={{ marginTop: 50 }}>
          {this.store.error && (
            <div style={{ marginBottom: 15 }}>
              <Alert message={this?.store?.error || t["ERROR"]} type="error" />
            </div>
          )}
          {this.store.consultations.length > 0 ? (
            <OfficeWorkingHours offices={this.store.consultations} />
          ) : (
            <div className="description">
              {t["CONSULTATIONS_NO_OFFICE_1"]}{" "}
              <a href={`+493034655777`}>{t["CONSULTATIONS_NO_OFFICE_2"]}</a>
            </div>
          )}
        </div>
        {this.store.consultations.length > 0 && this.store.calendarId && this?.props?.configStore?.consultationsBookingEnabled && (
            <Booking
                navigate={this.navigate}
                cancelEvent={this.cancelAppointment}
                contractStore={this.contractStore}
                store={this.store}
                isAppointmentRequested={this.store.isAppointmentRequested}
            />
        )}
        <div style={{ marginTop: "50px" }}>
          <p style={{ color: "#a0a7aa" }}>{t.CONSULTATIONS_PAGE_DISCLAIMER}</p>
        </div>
      </div>
    );
  }
}

export default inject(
  "consultationStore",
  "contractStore",
  "configStore"
)(withRouter(observer(ServicePointsPage)));
