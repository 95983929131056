/**
 * Will contain data about the logged in user.
 */
import { extendObservable } from 'mobx'
import { extractResponse, extractError } from '../service/response.service'
import tokenService from '../service/token.service'
import contractService from '../service/contract.service'
import get from 'lodash/get'

import { api, API_BASE } from '../service/api.service'

const PROFILE_ENDPOINT = `/user/profile`
const FORGOT_PASSWORD_ENDPOINT = `/user/forgot-password`
const RESET_PASSWORD_ENDPOINT = `/auth/reset-password`
const FIRST_SCHEDULE_ENDPOINT = `/user/first-schedule`
const SET_EMAIL_ENDPOINT = `/change-email`
const REVOKE_CHANGE_EMAIL_ENDPOINT = `/revoke-change-email`

class User {
  constructor() {
    extendObservable(this, {
      token: undefined,
      user: null,
      notification: null,
      contracts: [],
      activeContract: null,
      get contractAddress() {
        if (!this.activeContract) {
          return null
        }
        return this.activeContract.address
      },
      userType: undefined,
      userEmail: null,
      mailError: null
    })

    this.callbacks = []
  }

  initContractStore(store) {
    this.contractStore = store
  }

  async loadUser() {
    return await this.getProfile()
      .then(user => {
        this.setupUser(user)
        return user
      })
      .catch(err => {
        console.log(err)
        throw err
      })
  }

  setupUser(user) {
    this.user = user
    this.notification = user.notification
    this.contracts = user.contracts || []
    this.onUserChange()
  }

  /**
   * Method logs in the user and returns the user data
   * or throws an error with the message
   * of an error that occured while logging in.
   *
   * @param username
   * @param password
   *
   * @param  {String} username    User's username or email for login
   * @param  {String} password    User's password for login
   * @return {Promise}            Resolves to object containing user data and token
   */

  getFirstSchedule() {
    return api
      .get(FIRST_SCHEDULE_ENDPOINT)
      .then(res => extractResponse(res))
  }

  sendVerificationEmail = async (id) => {
    this.mailError = null
    try {
      const response = await api.post('/user/send-verification-email', { id })
      const responseData = extractResponse(response)
      this.mailError = 'RESEND_VERIFICATION_EMAIL_SUCCESS'
      return responseData
    } catch (err) {
      this.mailError = 'RESEND_VERIFICATION_EMAIL_ERROR'
      throw err
    }
  }

  postRedirectHandler = async (tokenClaims) => {
    try {
      const response = await api.post('/user/post-redirect', tokenClaims)
      const responseData = extractResponse(response)
      return responseData
    } catch (err) {
      throw err
    }
  }

  onUserChange = () => {
    this.callbacks.forEach(fn => fn(this.user))
  }

  addOnUserChangeHandler = callbackHandler => {
    this.callbacks.push(callbackHandler)
  }

  async getProfile() {
    return await api.get(PROFILE_ENDPOINT).then(response => {
      const userData = response.data
      return userData
    })
  }

  confirmNewsletterSubscription(id) {
    return api
      .post('/user/confirm-newsletter-subscription', { id })
      .then(response => {
        return extractResponse(response)
      })
      .catch(err => {
        throw new Error(extractError(err))
      })
  }

  recoverPasswordRequest({ email }) {
    return api
      .post(FORGOT_PASSWORD_ENDPOINT, { email: email })
      .then(response => {
        const responseData = extractResponse(response)
        return responseData
      })
      .catch(err => {
        const msg = extractError(
          err,
          'Error occured when trying to recover password.'
        )

        throw msg
      })
  }

  resetPassword({ password, confirmPassword, hash }) {
    return api
      .post(RESET_PASSWORD_ENDPOINT, { password, confirmPassword, hash })
      .then(res => {
        return { success: true }
      })
      .catch(err => {
        throw new Error(extractError(err))
      })
  }

  checkResetLink(hash) {
    return api.get(RESET_PASSWORD_ENDPOINT + '/' + hash)
  }

  changeEmail(hash) {
    return api
      .post(SET_EMAIL_ENDPOINT, { hash })
  }

  getUser() {
    return this.user
  }

  revokeChangeEmail(hash) {
    return api
      .post(REVOKE_CHANGE_EMAIL_ENDPOINT, { hash })
  }
}

export default new User()
