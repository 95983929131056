import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import { ImageUploader } from '../../components/formComponents'
import PropTypes from 'prop-types'
import t from '../../service/translate.service'
import { Alert } from 'antd'

class LoyaltyImageUpload extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <div>
        <Form className='loyalty-upload module'>
          <ImageUploader buttonText={this.props.type} files={this.props.files} />
          {this.props.uploadError && <Alert message={this.props.uploadError} type='error' />}
          <Form.Group className='actions text-center'>
            <div className='group'>
              <a
                href=''
                onClick={(e) => {
                  if (e.key !== 'Tab') {
                    e.preventDefault()
                  }
                  this.props.onSubmit(e)
                }}
                className='button primary'
                style={{
                  textDecoration: 'none',
                  color: 'unset'
                }}
              >
                {t['SUBMIT_' + this.props.type]}
              </a>
              <p>{t.UPLOAD_SUBMIT_TEXT}</p>
              <a
                href=''
                className='button tertiary' 
                onClick={(e) => {
                  if (e.key !== 'Tab') {
                    e.preventDefault()
                  }
                  this.props.onCancel(e)
                }}
                style={{
                  textDecoration: 'none',
                  color: 'unset'
                }}
              >
                {t.FORM_CANCEL}
              </a>
            </div>
          </Form.Group>
        </Form>
      </div>
    )
  }
}

LoyaltyImageUpload.propTypes = {
  files: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.string,
  uploadError: PropTypes.string
}
export default LoyaltyImageUpload
