/* eslint-disable no-unused-expressions */
import { api } from '../service/api.service'
import { extractResponse, extractError, extractErrorFromSap } from '../service/response.service'
import { autorun, extendObservable, toJS } from 'mobx'
import t from '../service/translate.service'
import find from 'lodash/find'
import User from '../store/user.store'
import userStore from '../store/user.store'
import {checkIsUserCondominium} from '../service/user.service'
import configStore from './config.store'
import { buildValidators, maxLength, required, isValidBIC, isValidIBAN } from '../service/validate'
import contractService from '../service/contract.service'
import moment from 'moment'

const CONTRACT_ENDPOINT = `/contract`

class ContractStore {
  constructor(configStore) {
    extendObservable(this, {
      configStore,
      contracts: [],
      loading: false,
      creatingContract: false,
      activeContracts: [],
      unverifiedContracts: [],
      contract: {
        contractNumber: '',
        address: '',
        email: ''
      },
      isRabFetched: false,
      submitted: false,
      selectedContract: null,
      selectedRentAccContract: null,
      ready: false,
      error: '',
      accountHistoryError: '',
      rentContractDocumentsEnabled: false,
      changeRentPaymentMethodEnabled: false,
      operationalCostPrepaymentEnabled: false,
      contractDetailsError: '',
      manual: false,
      rentDueDateOptIn: false,
      coldCosts: '',
      operationalCosts: '',
      heatingCosts: '',
      contractStartDate: '',
      contractEndDate: '',
      paymentMethod: '',
      tenants: [],
      totalCost: '',
      role: '',
      partnerId: '',
      certificates: [],
      verificationMessage: '',
      verificationStarted: false,
      verificationEmailSent: false,
      validationContractError: false,
      verificationBtn: '',
      contractEmails: [],
      condoDetails: {
        tenants: [],
        contractStartDate: '',
        contractEndDate: '',
        feesShared: '',
        feesNonShared: '',
        reserveFund: '',
        managementFee: '',
        total: '',
      }
    }),
    autorun(() => {
      this.configStore.getConfig()
      this.initContracts()
    })

  }

  get enabledCertificates() {
    const { rentCertificatePaidEnabled, rentCertificatePostalEnabled } = this.configStore
    return this.certificates.filter(c => {
      if (c.type === 'RC' && rentCertificatePostalEnabled) return c
      if (c.type === 'RPC' && rentCertificatePaidEnabled) return c
    })
  }

  clearError() {
    this.error = ''
  }

  async initContracts() {
    this.initConfig()
    await this.fetchContracts()
      .then(() => {
        if(this.activeContracts.length) {
          const contractNumber = window.location.pathname.split('/')[2] || ''
          this.selectedContract = this.activeContracts
            .filter(ac => ac.contractNumber === contractNumber)[0] || this.activeContracts[0]
        } else if(typeof this.activeContracts === 'object') this.selectedContract = this.activeContracts
        else  this.activeContracts = null
        this.ready = true
      })
      .catch(() => {
        this.ready = true
      })
  }

  changeContract(id) {
    this.selectedContract = find(this.contracts, { id })
  }

  get validators() {
    return {
      contractNumber: (val, label) => {
        if (this.submitted && !val) {
          return t['VALIDATE_REQUIRED'](label)
        }
        if (this.submitted && !/^[a-zA-Z0-9\s/-]+$/.test(val)) {
          return t['VALIDATE_CONTRACT_NUMBER']
        }
      },
      address: (val, label) =>
        this.submitted && !val && t['VALIDATE_ADDRESS'](label)
    }
  }

  sepaValidators(submitted) {
      return buildValidators(
        {
          sepaAccountHolderFirstName: [required, maxLength(60)],
          sepaAccountHolderLastName: [required, maxLength(60)],
          sepaIban: [required, isValidIBAN],
          sepaBic: [required, isValidBIC],
          sepaStartFrom: [required],
          sepaGrantMandate: [required],
        },
        this.submitted
      );
  }

  selfValidators(submitted) {
    return buildValidators(
      {
        selfPaymentStartFrom: [required],
        selfPaymentActive: [required],
      },
      this.submitted
    );
  }

  async delay(ms) {
    return new Promise(res => setTimeout(res, ms));
  }

  async getPaymentMethodDetails(contractNumber) {
    try {
      this.loading = true;
      const res = await api.get(`/contract/${contractNumber}/payment/details`);
      this.loading = false;
      return res.data;
    } catch (err) {
      this.loading = false;
      console.error('Error while fetching payment method details:', err);
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'];
      throw new Error(extractError(err));
    }
  }

  async validatePaymentData(contractNumber, paymentData, changingToMethod) {
    try {
      this.loading = true;
      let partnerId = this.partnerId;
      if (!partnerId) {
        const contractDetails = await this.getContractDetails(contractNumber);
        partnerId = contractDetails.partnerId;
      }
      const res = await api.post(`/contract/${partnerId}/${contractNumber}/payment/validate`, { ...paymentData, changingToMethod });
      this.loading = false;
      return res.data;
    } catch (err) {
      console.error('Error while validating payment data:', err);
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'];
      throw new Error(extractError(err));
    }
  }

  async changePaymentMethodToSepa(contractNumber, sepaDebitValues) {
    try {
      this.loading = true;
      let partnerId = this.partnerId;
      if (!partnerId) {
        const contractDetails = await this.getContractDetails(contractNumber);
        partnerId = contractDetails.partnerId;
      }
      const response = await api.post(`/contract/${partnerId}/${contractNumber}/payment`, {...sepaDebitValues, changingToMethod: 'sepaDirect'});
      this.submitted = false;
      this.loading = false;
      return response;
    } catch (err) {
      this.loading = false;
      console.error('Error while changing payment method to SEPA:', err);
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'];
      throw new Error(extractError(err));
    }
  }

  async changePaymentMethodToSelfPayment(contractNumber, selfPaymentValues) {
    try {
      this.loading = true;
      let partnerId = this.partnerId;
      if (!partnerId) {
        const contractDetails = await this.getContractDetails(contractNumber);
        partnerId = contractDetails.partnerId;
      }
      const response = await api.post(`/contract/${partnerId}/${contractNumber}/payment`, {...selfPaymentValues, changingToMethod: 'selfPayment'});
      this.submitted = false;
      this.loading = false;
      return response;
    } catch (err) {
      this.loading = false;
      console.error('Error while changing payment method to self payment:', err);
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'];
      throw new Error(extractError(err));
    }
  }

  async checkCertificate(contractNumber, type, checkMs) {
    const intervalMs = 2000
    const max = checkMs / intervalMs
    for (let index = 0; index < max; index++) {
      try {
        const res = await api.get(`/contract/${contractNumber}/certificate/${type}/check`)
        if (res.data.documentId) {
          this.certificates.replace(this.certificates.map((c) =>
            c.type === type ? {
              ...res.data,
              checking: false,
              percentage: 0
            } : c
          ))
          break
        }
        if (index === max - 1 && !res.data.documentId) {
          this.error = `${t['CERTIFICATE_RENT_ARCHIVE_ERROR']}`
          this.certificates.replace(this.certificates.map(c =>
            c.type === type ? {
              ...res.data,
              checking: false,
              percentage: 0
            } : c
          ))
          break
        }
        this.certificates.replace(this.certificates.map(c =>
          c.type === type ? {
            ...res.data,
            checking: true,
            percentage: parseInt(index / max * 100)
          } : c
        ))
        console.log('rechecking, no document id')
        await this.delay(intervalMs)
      } catch(error) {
        this.error = `Request for certificate type ${type} failed to submit, please contact our service center.`
        this.certificates.replace(this.certificates.map(c => ({...c, checking: false, percentage: 0})))
        break
      }
    }
  }

  async getCertificates(contractNumber) {
    this.error = ''

    const enabled = {
      RC: this.configStore['rentCertificatePostalEnabled'],
      RPC: this.configStore['rentCertificatePaidEnabled']
    }

    if (!enabled.RC && !enabled.RPC) return

    try {
      this.loading = true;
      const { data: certificates } = await api.post(`/contract/${contractNumber}/certificates`, { enabled })
      this.loading = false
      const unArchivedCertificates = certificates.filter(certificate => certificate.requestedAt && !certificate.documentId)
      if (unArchivedCertificates.length) {
        this.error = `${t['CERTIFICATE_RENT_ARCHIVE_ERROR']}`
      }
      this.certificates.replace(certificates)
      return certificates
    } catch (error) {
      this.loading = false;
      this.error = 'Failed to retrieve certificate requests, please contact our service center.';
      window.scrollTo(0, 0)
    }
  }

  async createCertificate(contractNumber, type) {
    try {
      this.loading = true;
      const { data } = await api.post(`/contract/${contractNumber}/certificate/${type}`)
      this.loading = false;
      this.error = ''

      if (!data.success || data.hasDue) {
        throw new Error(data.hasDue ? 'ZTEN_APP/000' : data.errorCode)
      }

      this.checkCertificate(contractNumber, type, data.certificate.checkMs)

      const {certificate} = data
      const certificates = this.certificates.map((c => c.type === certificate.type ? certificate : c))
      this.certificates.replace(certificates)
      window.scrollTo(0, 0)
      return certificate
    } catch (err) {
      this.loading = false;
      console.log('error while creating rent certificate:', err);
      this.error = err.message.includes('ZTEN') ? err.message : t['CERTIFICATE_RENT_CREATE_ERROR'];
      window.scrollTo(0, 0)
    }
  }

  newContract() {
    this.submitted = false
    this.creatingContract = false
    this.error = ''
    this.contract = {
      contractNumber: '',
      address: ''
    }
  }

  setVal(field, val) {
    this.contract[field] = val
  }

  async initConfig () {
    try {
      this.verificationStarted = false
      this.verificationEmailSent = false
      this.rentAccountEnabled = this.configStore.rentAccountEnabled
      this.rentContractDocumentsEnabled = this.configStore.rentContractDocumentsEnabled
      this.changeRentPaymentMethodEnabled = this.configStore.changeRentPaymentMethodEnabled
      this.ocCalculationsEnabled = this.configStore.ocCalculationsEnabled
      this.operationalCostPrepaymentEnabled = this.configStore.operationalCostPrepaymentEnabled
    } catch (err) {
      extractError(err)
    }
  }

  async getConsumptionRegistrationInfo(contractNumber) {
    try {
      this.loading = true;
      const res = await api.get(`/consumption/${contractNumber}/info`);
      this.rentDueDateOptIn = res.data?.overdueToApp;
      this.loading = false;
      return this.rentDueDateOptIn;
    } catch (err) {
      this.loading = false;
      console.log('error while fetching rent due opt in:', err);
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'];
      throw new Error(extractError(err));
    }
  }


  async consumptionRegister(contract, optIn, optingFor) {
    try {
      this.loading = true
      await api.post(`/consumption/register`, {
        appId: contract.id,
        consumptionInfoViaApp: optIn,
        contract_number: contract.contractNumber,
        optingFor: optingFor })
      this.loading = false
    } catch (err) {
      this.loading = false
      console.log('error while updating rent due opt in:', err)
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      throw new Error(extractError(err))
    }
  }

  async fetchContracts() {
    this.selectedRentAccContract = null
    this.loading = true
    this.isRabFetched = false
    this.verificationStarted = false
    this.verificationEmailSent = false
    return api.get('/contract').then(async res => {
      const contracts = extractResponse(res)
      this.loading = false
      this.contracts = contracts
      this.isRabFetched = true
      this.activeContracts = contracts.filter(c => c.active && c.valid)
      return contracts
    })
  }

  async fetchAccountBalance(contract) {
    this.accountHistoryError = ''
    this.loading = true
    await api.get(`/rentalaccount/${contract.contractNumber}/balance`)
    .then((res) => {
      const data = extractResponse(res)
      if (data?.account_lines?.length > 0) {
        let amount = 0
        for (let i = 0; i < data?.account_lines?.length; i++) {
          amount = amount + data?.account_lines[i]?.amount || 0
        }
        contract.rentAccountBalance = amount
      } else {
        contract.rentAccountBalance = 0
      }
      this.loading = false
    })
    .catch((err) => {
      this.accountHistoryError = t['RENT_ACCOUNT_HISTORY_ERROR']
      this.loading = false
      contract.rentAccountBalance = ''
      console.log(err)
    })
  }

  async fetchAccountHistory(contractNumber) {
    this.accountHistoryError = ''
    try {
      this.loading = true
      const res = await api.get(`/rentalaccount/${contractNumber}/balance`)
      const data = extractResponse(res)
      this.selectedRentAccContract = data?.account_lines || []
      this.loading = false
      if(data?.account_lines?.length < 1) {
        this.accountHistoryError = t['RENT_ACCOUNT_HISTORY_EMPTY']
      }
    } catch (error) {
      this.accountHistoryError = t['RENT_ACCOUNT_HISTORY_ERROR']
      this.loading = false
      console.log('Error while fetching account history:', error)
    }
  }

  async getContractVerificationInfo(contractNumber) {
    this.error = ''
    this.contractEmails = []
    try {
    this.loading = true
    const contactId = this.contracts.find((contract) => contract.contractNumber === contractNumber)
    const partnerId = contactId?.aimsContactId
    const res = await api.get(`/contract/verification?contractNumber=${contractNumber.replace('/', '-')}${partnerId ? '&partnerId=' + partnerId : ''}`)
    if(res) {
      const data = extractResponse(res)
      this.loading = false;
      return data
    }
    } catch(err) {
      this.loading = false;
      console.log('error while fetching user verification info:', err);
    }
  }

  async setContractVerificationInfo(contractNumber, verificationType, hash, aimsContactId) {
    try {
        this.loading = true;
        let partnerId = aimsContactId || await this.getPartnerId(contractNumber);
        const response = await api.post('/contract/verification', {
            partnerId,
            contractNumber,
            verificationType,
            hash
        });
        return response;

    } catch (err) {
        this.loading = false;
        console.error('Error fetching user verification info:', err);
        this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'];
        throw new Error(extractError(err));
    } finally {
        this.loading = false; // Ensure loading is set to false in all cases
    }
}

async getPartnerId(contractNumber) {
    if (!this.contracts.length) {
        await this.fetchContracts();
    }
    const contract = this.contracts.find(contract => contract.contractNumber === contractNumber.replace("/","-"));
    return contract ? contract.aimsContactId : null;
}

  async checkUserVerificationInfo() {
    const userId = User.user.id
    try {
      const status = await api.get(`/contract/user/verification/${userId}`)
      const data = extractResponse(status)
      return data

    } catch(err) {
      throw new Error(extractError(err));
    }
  }


  /**
   * @param {string} userId
   * @param {string} email
   * @param {boolean} isAddingContract
   * @param {string} contractNumber
   * @param {string} address
   * @param {object} rentPaymentMethodData
   * @param {string} changingToMethod
   */
  async verifyEmailViaEmail(email, isAddingContract, redirectUrl, loyaltyCashOut, loyaltyContractNumber, rentPaymentMethodData, changingToMethod) {
    this.error = ''
    try {
       await api.post('/contract/email-verification', {
        email,
        isAddingContract,
        contractNumber: loyaltyContractNumber || this.contract.contractNumber || this.selectedContract.contractNumber,
        address: this.contract.address || this.selectedContract.address,
        redirectUrl,
        loyaltyCashOut,
        rentPaymentMethodData,
        changingToMethod
      }).then((res) => {
        const data = extractResponse(res)
        this.verificationEmailSent = true
        return data
      })
    } catch(err) {
      const errorMsg = extractError(err)
      this.error = errorMsg === "LIMIT_EXCEEDED" ? t.LIMIT_EXCEEDED : t.SOMETHING_WENT_WRONG
    }
  }

  async sendEmailVerificationSms(mobile) {
    try {
     await api.post('/contract/user/verification/send-sms',  {mobile})
    } catch(err) {
      const errorMsg = extractError(err)
      this.error = errorMsg
      throw err
    }
  }


  async verifyEmailViaCode(code, contractNumber) {
    try {
      const res = await api.post('/contract/user/verification/code', {code, contractNumber})
      return res.data
    } catch(err) {
      const errorMsg = extractError(err)
      this.error = errorMsg === 'code_invalid' ? t.INVALID_CODE : t.SOMETHING_WENT_WRONG
    }
  }

  async checkEmailVerificationHash(hash) {
    try {
        this.loading = true;
        this.error = '';

        const response = await api.post(`/contract/email-verification/${hash}`);
        if (response) {
            const data = extractResponse(response);
            if (data?.isAddingContract) {
                await this.handleAddingContract(data, hash, 'E');
            } else {
                await this.setContractVerificationInfo(data.contractNumber, 'E', hash)
            }
            return data;
        }
    } catch (error) {
      this.validationContractError = true;
      this.error = extractErrorFromSap(error) || t[extractError(error)] || t['ERROR'];
      console.error('Error during email verification:', error);
      throw new Error(extractError(error))
    } finally {
        this.loading = false;
    }
}

async handleSmsVerification (data) {
  try {
    if (data?.isAddingContract) {
      if(!data.email) data.email = User.user.email
      await this.handleAddingContract(data, data.hash, 'T');
     } else {
      await this.setContractVerificationInfo(data.contractNumber, 'T', data.hash)
    }
    return data
  } catch(error) {
    this.error = extractErrorFromSap(error) || t[extractError(error)] || t['ERROR'];
    console.error('Error during sms verification:', error);
    throw new Error(extractError(error))
  }

}

async handleAddingContract(data, hash, verificationMethod) {
    this.contract.contractNumber = data.contractNumber.replace('-', '/');
    this.contract.address = data.address;
    try {
        const response = await this.submitContract(false, data.email);
        console.log('Submission response:', response);
        data.submissionError = false;
        await this.setContractVerificationInfo(response.contractNumber, verificationMethod, hash, response.aimsContactId);
        this.initContracts()
        await userStore.getProfile()
    } catch (error) {
        data.submissionError = true;
        this.validationContractError = true;
    }
}


  async submitContract(manual = false, email) {
    this.submitted = true
    this.manual = manual
    if (find(this.validators, (v, k) => v(this.contract[k]))) {
      return new Promise((resolve, reject) => {
        resolve('err')
      })
    }
    this.creatingContract = true
    if(email) this.contract.email = email
    return api
      .post(CONTRACT_ENDPOINT, Object.assign({}, this.contract, { manual }))
      .then(res => {
        const contract = extractResponse(res)
        this.contracts.push(contract)
        if (contract.valid) {
          this.activeContracts.push(contract)
          this.selectedContract = contract
        }
        User.contracts.push(contract)
        this.creatingContract = false
        this.submitted = false

        User.getProfile()
        return contract
      })
      .catch((err, a) => {
        this.creatingContract = false
        this.submitted = false
        this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
        throw new Error(extractError(err))
      })
  }

  formatCosts(cost) {
    return `${Math.abs(cost).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} €`;
  }

  async getContractDetails(contractNumber) {
    this.contractDetailsError = ''
    this.contractEmails = []
    let isCondomonium = false
    const condoContract = this.contracts.find((c) => c.contractNumber === contractNumber)
    if(condoContract) isCondomonium = checkIsUserCondominium([condoContract])
    try {
      const res = await api.get(`/contract/${contractNumber.replace('/', '-')}/contract-overview/details`)
      if (res) {
        const data = extractResponse(res)

        data?.tenants.forEach(tenant => {
          if(tenant?.email || tenant?.mobile || tenant?.telephone) {
            this.contractEmails.push(tenant)}
        });

        if (isCondomonium) {
          this.tenants = data.tenants
          this.setContractDetailsCondo(res)
        } else {
          this.coldCosts = this.formatCosts(data.coldRent)
          this.operationalCosts = this.formatCosts(data.operationalCosts)
          this.heatingCosts = this.formatCosts(data.heatingCosts)
          this.contractStartDate = data.contractStartDate
          this.contractEndDate = data.contractEndDate
          this.tenants = data.tenants
          this.paymentMethod = data.paymentMethod
          this.totalCost = this.formatCosts(data.totalCost)
          this.role = data.role
          this.partnerId = data.partnerId
          return data
        }
      }
    } catch(err) {
      this.contractDetailsError = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      throw new Error(extractError(err))
    }
  }

  async setContractDetailsCondo(res) {
    for (const key in res.data) {
      this.condoDetails[key] = res.data[key]
    }
  }

  checkEmailsInTenants = (data) => {
    const hasTenantEmails = data.tenants && Array.isArray(data.tenants);
    return hasTenantEmails && data.tenants.some(tenant => tenant.email || tenant.mobile || tenant.telephone);
  }
}

export default ContractStore
