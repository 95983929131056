/**
 * Will contain data about the logged in user.
 */
import { extractResponse, extractError } from '../service/response.service'
import { api } from '../service/api.service'

const FEEDBACK_ENDPOINT = `/feedback`

class Feedback {
  send ({ rating, description, allowPublishing }) {
    return api
      .post(FEEDBACK_ENDPOINT, {
        rating,
        description,
        allowPublishing: !!allowPublishing
      })
      .then(response => {
        const responseData = extractResponse(response)
        return responseData
      })
      .catch(err => {
        const msg = extractError(err)
        this.loginError = msg

        throw msg
      })
  }
}

export default Feedback
