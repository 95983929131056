import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '../azureB2C/authConfig'
import axios from "axios"
import { publicRoutes } from '../const/publicRoutes'
const CSRF_TOKEN_ENDPOINT = '/generate-csrf'

const cfg = { ...msalConfig }
const pathname = window.location.pathname

export const getAccessToken = async () => {
  const pca = new PublicClientApplication(cfg)
  await pca.initialize()
  const accounts = pca.getAllAccounts()
  const account = accounts[0]

  let authority = process.env.REACT_APP_AZ_SIGN_IN_EMAIL_AUTHORITY

  switch(localStorage.getItem('auth_grant')) {
    case 'sign_up':
      authority = process.env.REACT_APP_AZ_SIGN_UP_EMAIL_AUTHORITY
      break;
    case 'sign_up_sign_in_google':
      authority = process.env.REACT_APP_AZ_SIGNUP_SIGNIN_GOOGLE_AUTHORITY
      break;
    case 'sign_up_sign_in_apple':
      authority = process.env.REACT_APP_AZ_SIGNUP_SIGNIN_APPLE_AUTHORITY
      break;
    case 'passwordreset':
      authority = process.env.REACT_APP_AZ_PASSWORD_RESET_AUTHORITY
      break
    case 'passwordchange':
      authority = process.env.REACT_APP_AZ_CHANGE_PASSWORD_AUTHORITY
      break
    default:
      authority = process.env.REACT_APP_AZ_SIGN_IN_EMAIL_AUTHORITY
  }
  
  const accessTokenRequest = {
    scopes: [process.env.REACT_APP_AZ_CLIENT_ID || ''],
    account,
    authority
  }
  try {
    const resp = await pca.acquireTokenSilent(accessTokenRequest)
    return resp?.accessToken
  } catch (err) {
    const publicRoute = publicRoutes.find((route) => {
      if (pathname.includes(route)) {
        return route
      }
    })
    if (!publicRoute && !err?.errorMessage?.includes("Network request failed: If the browser threw a CORS error, check that the redirectUri is registered in the Azure App Portal as type 'SPA'")) {
      pca.logoutRedirect()
    }
  }
}

export const generateCsrfToken = async () => {
  
  try {
    const response = await axios.get(CSRF_TOKEN_ENDPOINT, {
      headers: {
        'Content-Type': 'application/json',
        'GCP-App-Info': `GCP App - web`,
      }
    })

    return response.data.crumb
  } catch (err) {
    console.log(err)
    console.log(`Error generating csrf token`)
  }
}

export const logout = async () => {
  const pca = new PublicClientApplication(cfg)
  pca.logoutRedirect()
}