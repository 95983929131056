import React from "react";
import t from "../../service/translate.service";
import ThankYouOverlay from "../../components/ThankYouOverlay";
import { useMsal } from "@azure/msal-react";

const ConfirmRegistration = () => {
  const { instance } = useMsal()
  return (
    <ThankYouOverlay
      style={{ left: 0 }}
      title={t.THANK_YOU}
      description={t.EMAIL_CONFIRMATION_THANK_YOU_TEXT}
      buttonText={t.REGISTRATION_BACK_TO_LOGIN}
      handleClick={() => instance.logout()}
    />
  );
};

export default ConfirmRegistration;
