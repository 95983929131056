import { Link } from "react-router-dom";
import IconsCloseSmall from "../assets/svg/iconsCloseSmall.svg";
import {
  getAlertProperties,
  getAlertInfo,
  prepareContent,
} from "../service/alert.service";
import t from "../service/translate.service";
import moment from "moment";

export const StackedNotifications = ({
  notifications,
  onClose,
  onContentClick,
}) => {
  const items = notifications.filter((notification) => !notification.seen);
  return (
    items.length > 0 && (
      <div className="alert-list">
        {items.map((notification, index) => {
          const { statusClass } = getAlertProperties(notification);
          const { sName, isUrl } = getAlertInfo(notification);
          const { alertStatus } = notification;
          return (
            <div
              className={
                notification.type === "general" ||
                notification.type === "letter"
                  ? "itemGeneral notification-group"
                  : `item notification-group ${
                      alertStatus ? `contract-overview-item-${alertStatus}` : ""
                    }`
              }
              key={index}
            >
              <div
                className={`${
                  notification.type === "letter" ? "letters" : ""
                } notification`}
              >
                {index === 0 ? (
                  <Link
                    className="close"
                    style={{ zIndex: "99" }}
                    onClick={() => onClose(notification.id)}
                  >
                    <img src={IconsCloseSmall} />
                  </Link>
                ) : (
                  <div
                    className="close"
                    style={{ zIndex: "99" }}
                    onClick={() => onClose(notification.id)}
                  >
                    <img src={IconsCloseSmall} />
                  </div>
                )}
                <div
                  className="content"
                  onClick={() => onContentClick(notification)}
                  style={{ cursor: "pointer " }}
                >
                  <div className="message">
                    <div className="status">
                      <span className={`status ${statusClass}`}>
                        {t[statusClass]}
                      </span>{" "}
                      {notification.sName || sName}
                    </div>
                    {isUrl || notification.setDangerously ? (
                      isUrl ? (
                        <div className="contract-number">
                          {prepareContent(notification)}
                        </div>
                      ) : (
                        <div
                          className="contract-number"
                          dangerouslySetInnerHTML={{
                            __html: notification.content,
                          }}
                        ></div>
                      )
                    ) : (
                      <div className="basic-content">
                        <p>{notification.content}</p>
                      </div>
                    )}
                    {notification.created_at && (
                      <div className="date">
                        {moment(notification.created_at)
                          .lang(
                            `${
                              localStorage.getItem("locale") === "de"
                                ? "de"
                                : "en"
                            }`
                          )
                          .format("dddd, MMMM Do YYYY, HH:mm")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};
