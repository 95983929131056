import t from './translate.service'
import DOMPurify from "dompurify";
import { inboxCategories } from '../config';

const lang = localStorage.getItem("locale") === "en"

export function getAlertProperties (alert) {
  let statusClass = ''
  let statusName = t.SCHEDULED_CONSULTATION
  if (alert.type === 'contract') {
    statusName = t.CONTRACT
    if (alert.status === 'VERIFIED') {
      statusClass = 'CONTRACT_VERIFIED'
    } else if (alert.status === 'IN_PROGRESS') {
      statusClass = 'PROFILE_CONTRACTS_STATUS_PENDING_SHORT'
    } else if (alert.status === 'RENT_OVERDUE') {
      statusClass = 'RENT_OVERDUE'
    } else if(alert.status === 'UNVERIFIED') {
      statusClass = 'CONTRACT_VERIFICATION_BTN'
    } else {
      statusClass = 'PROFILE_CONTRACTS_STATUS_INACTIVE'
    }
  } else if (alert.type === 'damage_report') {
    statusName = t.INQUIRY
    if (alert.status === 'RESOLVED') {
      statusClass = 'INQUIRY_RESOLVED'
    } else if (alert.status === 'IN_PROGRESS') {
      statusClass = 'INQUIRY_IN_PROGRESS'
    } else {
      statusClass = 'INQUIRY_NEW'
    }

  } else if (alert.type === 'general') {
    statusName = 'general'
    statusClass = 'general'
  }
  else if (alert.type === 'letter') {
    statusName = 'letter'
    statusClass = 'general'
  }
  else if (alert.type === 'letter') {
    statusName = 'letter'
    statusClass = 'general'
  }
  else if (alert.type === 'oc_inspection') {
    statusName = 'oc_inspection'
    statusClass = 'general'
  }
  else if (alert.type === 'RC' || (alert.type === 'certificate' && alert.status === 'RC')) {
    statusName = t.CERTIFICATE_RENT_RC_SUCCESS
    statusClass = ''
  }
  else if (alert.type === 'RPC' || (alert.type === 'certificate' && alert.status === 'RPC')) {
    statusName = t.CERTIFICATE_RENT_RPC_SUCCESS
    statusClass = ''
  }


  return { statusName, statusClass }
}

export const prepareContent = (notification) => {
  let content = lang === "en" ? notification.content : notification.contentDe
  const regex =
    /(https?:\/\/[^\s<>"',]+(?<![.,]))|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const parts = content.split(regex);
  return parts.map((part, index) => {
    if (part.match(regex)) {
      return (
        <a
          key={index}
          href={part.startsWith("http") ? part : `mailto:${part}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {part}
        </a>
      )
    }
    return <span key={index}>{part}</span>;
  });
};

export const getAlertInfo = (alert) => {
  const { statusName, statusClass } = getAlertProperties(alert);
  let content = lang ? alert.content : alert.contentDe;
  content = DOMPurify.sanitize(content);
  const regEx =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  const isUrl = regEx.test(content);
  let sName = statusName;
  const foundCategory = inboxCategories.find((category) => category.type === statusName );
  if (foundCategory) {
    if (foundCategory.type === 'other') {
      sName = lang === "en" ? alert.title : alert.titleDe
    } else {
      sName = foundCategory.name;
    }
  }
  return {statusClass, content, sName, isUrl, lang}
}