import { api } from "../service/api.service";
import { extractError, extractErrorFromSap } from "../service/response.service";
import { extendObservable } from "mobx";
import t from "../service/translate.service";

class ConsumptionStore {
  constructor() {
    extendObservable(this, {
      energyToApp: false,
      lettersToApp: false,
      overdueToApp: false,
      loading: false,
      error: "",
    });
  }

  async getConsumptionRegistrationInfo(contractNumber, optingFor) {
    try {
      this.loading = true;
      const res = await api.get(`/consumption/${contractNumber}/info`);
      if (optingFor === "Energy") {
        // To fetch energy opt-in we must send an empty string(configuration on SAP is like that currently)
        this.energyToApp = res.data?.consumptionInfoViaApp;
        this.loading = false;
        return this.energyToApp;
      } else if (optingFor === "Letters") {
        this.lettersToApp = res.data?.lettersToApp;
        this.loading = false;
        return this.lettersToApp;
      } else if (optingFor === "Overdue") {
        this.overdueToApp = res.data?.overdueToApp;
        this.loading = false;
        return this.overdueToApp;
      } else {
        throw new Error("Invalid optingFor value");
      }
    } catch (err) {
      this.loading = false;
      console.log("error while fetching consumption registration info:", err);
      this.error =
        extractErrorFromSap(err) || t[extractError(err)] || t["ERROR"];
      throw new Error(extractError(err));
    }
  }

  async consumptionRegister(contract, optIn, optingFor) {
    try {
      this.loading = true;
      await api.post(`/consumption/register`, {
        appId: contract.id,
        consumptionInfoViaApp: optIn,
        contract_number: contract.contractNumber,
        optingFor: optingFor,
      });
      this.loading = false;
    } catch (err) {
      this.loading = false;
      console.log("error while updating consumption registration:", err);
      this.error =
        extractErrorFromSap(err) || t[extractError(err)] || t["ERROR"];
      throw new Error(extractError(err));
    }
  }
}

export default ConsumptionStore;
