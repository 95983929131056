import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import FreshChat from 'react-freshchat'
import { extendObservable } from 'mobx'
import { FreshChatIcon, BackIcon } from '../components/icons'
import LoadingPageOverlay from '../components/LoadingPageOverlay'
import find from 'lodash/find'
import Emitter from '../service/event.emitter'
import { api } from '../service/api.service'

const freshchatHost = 'https://wchat.eu.freshchat.com'
const freshchatToken = window.location.hostname === 'serviceportal.grandcityproperty.de' ? 'a4b629c1-7844-4891-a1ff-21ce7f02bcd2' : '93076ee1-a840-485f-99e5-d16682b05eec'

class Freshchat extends Component {
  constructor (props) {
    super(props)
    extendObservable(this, {
      agents: '',
      settingContract: false,
      loading: false,
      activeContracts: this?.props?.user?.user?.contracts?.filter(x => x.active && x.valid) || [],
      selectedContract: {
        address: '',
        contractNumber: ''
      }
    })
    this.showChat = this.showChat.bind(this)
    this.toggleOpenChat()
  }

  static propTypes = {
    user: PropTypes.object.isRequired
  }

  componentDidMount () {
    Emitter.on('openChat', () => {
      this.showChat()
    })
  }

  componentWillUnmount () {
    Emitter.off('openChat')
  }

  toggleOpenChat = (e) => {
    e && e.stopPropagation()
    if (this.activeContracts.length === 1) {
      this.showChat(this.activeContracts[0].contractNumber)
    } else if (this.activeContracts.length === 0) {
      this.showChat()
    }
    if (e && (e.target.tagName === 'svg' || e.target.className === 'close-chat' || e.target.className === 'open-chat' || e.target.tagName === 'path')) {
      this.settingContract = !this.settingContract
    }
  }
  async showChat (contractNumber) {
    this.loading = true
    this.selectedContract = contractNumber ? find(this.activeContracts, { contractNumber }) : this.selectedContract

    try {
      if (contractNumber) {
        const res = await api.get(`/contract/${contractNumber}/property-managers`)
        const agents = res.data.map(agent => agent.partner_email).join()
        this.agents = agents || 'none'
      } else {
        this.agents = 'none'
      }
    } catch (err) {
      this.agents = 'none'
    }

    //FIX for LoadingPageOverlay being constantly shown
    //Real issue: GET https://wchat.freshchat.com/js/widget.js throwing 403 error
    this.loading = false
  }
  closeChat = () => {
    window.fcWidget.destroy()
    this.settingContract = false
    this.agents = ''
  }
  closeSelectContract = () => {
    this.loading = false
    this.settingContract = false
  }
  render () {
    const { user } = this.props.user
    return (
      <div>
        {this.loading && <LoadingPageOverlay />}
        {!this.agents
          ? (<div onClick={e => this.toggleOpenChat(e)} className='chat-wrap'>

            {this.settingContract ? <button className='close-chat'>x</button> : <button className='open-chat' onClick={e => this.toggleOpenChat(e)}>{FreshChatIcon}</button>}

            <div className='contract-select-wrap' style={{ display: `${this.settingContract ? 'block' : 'none'}` }}>
              <div className='contract-select'>
                <header>
                  <div className='wrapper'>
                    <div className='back' onClick={e => this.toggleOpenChat(e)}>
                      {BackIcon}
                    </div>
                    <div className='title'>Select contract</div>
                    <div style={{ width: '20px' }} />
                  </div>
                </header>
                {this.activeContracts.map(contract => {
                  return (
                    <ContractCard key={contract.id}
                      contract={contract}
                      handleClick={() => this.showChat(contract.contractNumber)} />
                  )
                })}
              </div>
            </div>
          </div>)
          : (
            <FreshChat
              token={freshchatToken}
              host={freshchatHost}
              locale={localStorage.getItem('locale') || 'en'}
              externalId={user.id}
              restoreId={user.freshchatConversationId}
              config={{
                agent: {
                  hideName: true,
                  hidePic: true
                },
                disableNotifications: true
              }}
              onInit={widget => {
                widget.user.setProperties({
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  phone: user.phone,
                  agents: this.agents,
                  Address: this.selectedContract.address,
                  Contract_number: this.selectedContract.contractNumber
                })

                widget.on('user:created', async function (resp) {
                  try {
                    var status = resp && resp.status
                    var data = resp && resp.data

                    if (status === 200) {
                      if (data.restoreId) {
                        await api.put('/user/conversation/save', { freshchatConversationId: data.restoreId })
                      }
                    }
                  } catch (err) {
                    console.error('get user restore id error:', err)
                  }
                })

                if (this.activeContracts.length > 1) {
                  widget.on('widget:closed', this.closeChat)
                  widget.on('widget:opened', this.closeSelectContract)
                  widget.open()
                } else {
                  this.closeSelectContract()
                }
              }}
            />
          )}
      </div>
    )
  }
}

const ContractCard = ({ contract, handleClick }) => {
  return (
    <a
      href=''
      className='chat-contract-card'
      onClick={(e) => {
        if (e.key !== 'Tab') {
          e.preventDefault()
        }
        handleClick()
      }}
      style={{
        textDecoration: 'none',
        color: 'unset',
        textAlign: 'left'
      }}
    >
      <div>
        <p className='number'>{contract.contractNumber}</p>
        <p>{contract.address}</p>
      </div>
    </a>
  )
}

ContractCard.propTypes = {
  contract: PropTypes.object,
  handleClick: PropTypes.func
}
export default observer(Freshchat)
