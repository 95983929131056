import React from 'react'
import { observer } from 'mobx-react'
import { Form, Button } from 'react-bootstrap'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'

const LoyaltyPaymentKeepPoints = observer(({ store, onCancel, onSubmit, uploadError, backToLoyalty }) => {
  // const { setCode, code } = store

  return (
    <div className='loyalty-upload module'>
      <div className='loyalty-code-top text-center'>
        <PrimaryTitle title={t.LOYALTY_PAYMENT_KEEP_POINTS_TITLE} />
        <p>{t.LOYALTY_PAYMENT_KEEP_POINTS_TEXT1}</p>
        <p>{t.LOYALTY_PAYMENT_KEEP_POINTS_TEXT2}</p>
        <p>{t.LOYALTY_PAYMENT_KEEP_POINTS_TEXT3}</p>
      </div>
      <Form >
        <Form.Group className='actions text-center'>
          <div className='group'>
            <a
              href=''
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                backToLoyalty()
              }}
              className='button primary'
              style={{
                textDecoration: 'none',
                color: 'unset',
                marginBottom: '20px'
              }}
            >
              {t.LOYALTY_PAYMENT_KEEP_POINTS_BTN}
            </a>
          </div>
        </Form.Group>
      </Form>
        <a
          href=''
          onClick={(e) => {
            if (e.key !== 'Tab') {
              e.preventDefault()
            }
            onCancel(e)
          }}
          className='button tertiary'
          style={{
            textDecoration: 'none',
            color: 'unset'
          }}
        >
          {t.FORM_CANCEL}
        </a>
    </div>
  )
})

export default LoyaltyPaymentKeepPoints
