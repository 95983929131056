import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import user from "../../store/user.store";
import t from "../../service/translate.service";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import ThankYouOverlay from "../../components/ThankYouOverlay";
import withRouter from "../../components/withRouter";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import { useMsal } from "@azure/msal-react";

const NewsletterSubscribeConfirm = () => {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const id = window.location.pathname.replace(
          "/confirm-newsletter-subscription/",
          ""
        );
        const res = await user.confirmNewsletterSubscription(id);
        const { success, alreadySubscribed, mailjetError } = res;
        if (mailjetError) {
          setStatus("mailjetError");
        } else if (success && alreadySubscribed) {
          setStatus("alreadySubscribed");
        } else if (success && !alreadySubscribed) {
          setStatus("success");
        }
      } catch (err) {
        setStatus(null);
        setError(err);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <LoadingPageOverlay />;
  }

  return (
    <div>
      {status === "success" && (
        <ThankYouOverlay
          title={t.THANK_YOU}
          description={t.CONFIRM_NEWSLETTER_SUBSCRIPTION}
          //buttonText={t.REGISTRATION_BACK_TO_LOGIN}
          //handleClick={() => instance.logout()}
        />
      )}
      {status === "alreadySubscribed" && (
        <ThankYouOverlay
          title={t.NEWSLETTER_SUBSCRIBED_ERROR}
          description={t.CONFIRM_NEWSLETTER_SUBSCRIPTION_ALREADY_SUBSCRIBED}
          //buttonText={t.REGISTRATION_BACK_TO_LOGIN}
          //handleClick={() => instance.logout()}
        />
      )}
      {status === "mailjetError" && (
        <ThankYouOverlay
          title={t.ERROR}
          description={t.CONFIRM_NEWSLETTER_SUBSCRIPTION_MAILJET_ERROR}
          //buttonText={t.REGISTRATION_BACK_TO_LOGIN}
          //handleClick={() => instance.logout()}
        />
      )}
      {error && <ErrorOverlay handleClick={() => instance.logout()} />}
    </div>
  );
};

export default withRouter(observer(NewsletterSubscribeConfirm));
