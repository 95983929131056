import React, { useState } from "react";
import t from "../service/translate.service";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import ThankYouOverlay from "./ThankYouOverlay";
import { useMsal } from "@azure/msal-react";
import LoadingPageOverlay from "./LoadingPageOverlay";

const RegistrationThankYou = (props) => {
  const { instance } = useMsal()
  const [loading, setLoading] = useState(false)

  if (loading) {
    return <LoadingPageOverlay />
  }

  const resendVerificationEmail = async () => {
    setLoading(true)
    try {
      if (props) {
        await props.userStore.sendVerificationEmail(props.userStore.user.id)
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  return (
    <ThankYouOverlay
      style={{ left: 0 }}
      title={t.THANK_YOU}
      description={
        props.userStore.mailError
          ? t[props.userStore.mailError]
          : t.REGISTRATION_THANK_YOU_TEXT
      }
      buttonText={t.REGISTRATION_BACK_TO_LOGIN}
      handleClick={() => instance.logout()}
      cancelButtonText={t.RESEND_VERIFICATION_EMAIL}
      handleCancel={async () => await resendVerificationEmail()}
    />
  );
};
RegistrationThankYou.propTypes = {
  userStore: PropTypes.object.isRequired,
};
export default inject("userStore")(observer(RegistrationThankYou));
