import React, { Component } from 'react'
import history from '../service/history.service'
import t from '../service/translate.service'
import { observer } from 'mobx-react'
import { Rate } from 'antd'
import withRouter from './withRouter'

class Footer extends Component {
  constructor(props) {
    super(props)
  }
  onChange = (rating) => {
    this.props.navigate(`/feedback/${rating}`)
  }

  render() {
    const prospectiveTenantsPhone = '+493034655777'
    // const tenantsPhone = '+493034655777'
    // const emergencyPhone = '+493034655777'
    return (
      <footer>
        <div className='wrapper'>
          <div className='group'>
            <div className='links'>
              <div className='item'>
                <div className='container'>
                  <div className='title'>{t.CONTACT}</div>
                  <div>
                    <a href='/imprint'>{t.IMPRINT}</a>
                  </div>
                  <div>
                    <a href='/public/static/privacy-policy-en.html'>{t.FOOTER_PRIVACY_POLICY}</a>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='container phone-numbers'>
                  <div className='title'>{t.IMPORTANT_PHONE_NUMBERS}</div>
                  <div>{t.SERVICE_CENTER}: <a href={`tel:${prospectiveTenantsPhone}`}>{prospectiveTenantsPhone}</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className='feedback'>
            <div className='title'>{t.FEEDBACK_FOOTER_MESSAGE}</div>
            <div className='rating'>
              <Rate onChange={this.onChange} />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default withRouter(observer(Footer))
