import React from 'react'
import PropTypes from 'prop-types'

function Categories ({
  categories,
  selectedCategoryId,
  handleClick
}) {
  return (
    <div className='category-tabs'>
      {categories.map((category, i) => {
        const categoryStyle = {
          paddingRight: i === categories.length ? 0 : 21
        }
        const selectedCategory = category.id === selectedCategoryId
        return (
          <a href='' className={selectedCategory ? 'active' : ''} key={category.id} style={categoryStyle} onClick={(e) => handleClick(e, category)}>
            <h3>{localStorage.getItem('locale') === 'en' ? category.name : category.nameDe}</h3>
          </a>
        )
      })}
    </div>
  )
}
Categories.propTypes = {
  categories: PropTypes.array,
  selectedCategoryId: PropTypes.number,
  handleClick: PropTypes.func
}
export default Categories
