import { LogLevel } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZ_CLIENT_ID || '',
    authority: process.env.REACT_APP_AZ_AUTHORITY || '',
    redirectUri: process.env.REACT_APP_AZ_REDIRECT_URL || '',
    postLogoutRedirectUri: process.env.REACT_APP_AZ_POST_LOGOUT_REDIRECT_URL || '',
    clientCapabilities: [process.env.REACT_APP_AZ_CLIENT_CAPABILITIES || ''],
    knownAuthorities: [process.env.REACT_APP_AZ_KNOWN_AUTHORITIES || ''],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
        case LogLevel.Error:
          return
        case LogLevel.Info:
          return
        case LogLevel.Verbose:
          return
        case LogLevel.Warning:
          if (message?.includes('No user hint provided. The authorization server may need more information to complete this request')) {
            throw new Error()
          }
          return
        }
      },
    },
  },
}

export const b2cPolicies = {
  authorities: {
    editProfile: {
      authority: process.env.REACT_APP_AZ_EDIT_PROFILE_AUTHORITY,
      scopes: []
    },
    changePassword: {
      authority: process.env.REACT_APP_AZ_CHANGE_PASSWORD_AUTHORITY,
      scopes: []
    },
    changeEmail: {
      authority: process.env.REACT_APP_AZ_CHANGE_EMAIL_AUTHORITY,
      scopes: []
    },
    emailSignUp: {
      authority: process.env.REACT_APP_AZ_SIGN_UP_EMAIL_AUTHORITY,
      scopes: []
    },
    emailSignIn: {
      authority: process.env.REACT_APP_AZ_SIGN_IN_EMAIL_AUTHORITY,
      scopes: []
    },
    googleSignUpSignIn: {
      authority: process.env.REACT_APP_AZ_SIGNUP_SIGNIN_GOOGLE_AUTHORITY,
      scopes: []
    },
    appleSignUpSignIn: {
      authority: process.env.REACT_APP_AZ_SIGNUP_SIGNIN_APPLE_AUTHORITY,
      scopes: []
    },
    emailForgotPassword: {
      authority: process.env.REACT_APP_AZ_PASSWORD_RESET_AUTHORITY,
      scopes: []
    }
  },
  authorityDomain: 'auth-uat.aroundtown.de',
}

export const appRoles = {
  TaskUser: 'TaskUser',
  TaskAdmin: 'TaskAdmin'
}
