import React, { useState } from "react";
import ProfileStore from "../../store/profile.store";
import historyService from "../../service/history.service";
import t from "../../service/translate.service";
import { observer } from "mobx-react";
import ThankYouOverlay from "../../components/ThankYouOverlay";
import { useMsal } from "@azure/msal-react";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";

const DeleteProfile = () => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false)
  const profileStore = new ProfileStore()


  const deleteAccount = async () => {
    setLoading(true)
    try {
      await profileStore.deleteAccount()
      await instance.logout()
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  };

  if (loading) {
    return (
      <LoadingPageOverlay />
    )
  }

  return (
    <ThankYouOverlay
      title={t.DELETE_ACCOUNT}
      description={t.DELETE_ACCOUNT_TEXT}
      buttonText={t.DELETE_ACCOUNT_YES}
      buttonLink={""}
      handleClick={deleteAccount}
      cancelButtonText={t.DELETE_ACCOUNT_NO}
      handleCancel={() => historyService.back()}
    />
  );
}

export default observer(DeleteProfile);
