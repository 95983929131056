import { useEffect, useState } from "react";
import ContractStore from "../../store/contract.store";
import AddContract from "./AddContract";
import { inject, observer } from "mobx-react";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import ThankYouOverlay from "../../components/ThankYouOverlay";
import t from "../../service/translate.service";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import { extractError } from "../../service/response.service";
import { VerificationModal } from "../../components/VerificationModal";

const ContractVerificationHandler = () => {
  const contractStore = new ContractStore();
  const location = useLocation()
  const [contractSubmissionFailed, setContractSubmissionFailed] =
    useState(false);
  const [verificationCompleted, setVerificationCompleted] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [address, setAddress] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [openSuccessModal, setOpenSuccesModal] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [data, setData] = useState()

  useEffect(() => {
  if(location.state) {
     handleSmsVerification(location.state)
     return
  }
    const verifyEmail = async () => {
      setLoader(true);
      const hash = new URLSearchParams(window.location.search).get("hash");
      if (hash) {
        try {
          const res = await contractStore.checkEmailVerificationHash(hash)
          if (res) {
            if (res.isAddingContract) {
              handleContractSubmission(res);
            } else if (res.redirectUrl) {
               setData(res)
               setOpenSuccesModal(true);
            }
          }
        } catch (err) {
          handleVerificationError(err);
        }
      }
      setLoader(false);
    };
    verifyEmail();
  }, []);


  const handleSmsVerification = async (data) => {
    setLoader(true)
    contractStore.handleSmsVerification(data).then(() => {
      if(data.isAddingContract) {
        handleContractSubmission(data)
       } else if(data.redirectUrl) {
        setData(data)
        setOpenSuccesModal(true)
       }
       setLoader(false)
    }).catch((err) => {
      handleVerificationError(err)
      setLoader(false)
    })

  }

  const handleContractSubmission = (data) => {
    setLoader(true)
    if (data.submissionError) {
      setSubmissionError(t.CONTRACT_VERIFICATION_INVALID_ADDRESS);
      setContractSubmissionFailed(true);
      setContractNumber(data.contractNumber);
      setAddress(data.address);
      setLoader(false)
    } else {
      setVerificationCompleted(true);
      setOpenSuccesModal(true)
      setLoader(false)
    }
  };

  const handleRedirect = () => {
    const options = {
      state: {
        loyaltyCashOut: data?.loyaltyCashOut || undefined,
        rentPaymentMethodData: {...data?.rentPaymentMethodData, changingToMethod: data?.changingToMethod} || undefined,
      },
    };
    if(data?.redirectUrl) navigate(data.redirectUrl, options);
    else navigate('/contracts')
  };

  const onCloseModal = () => {
    setOpenSuccesModal(false)
    handleRedirect()
  }

  const handleVerificationError = (err) => {
    const error = extractError(err);
    if (error === "verification_link_expired") {
      setVerificationError(
        t.CONTRACT_VERIFICATION_INVALID_LINK
      );
    } else {
      setVerificationError(t.CONTRACT_VERIFICATION_ERROR_GENERAL_MSG);
    }
  };

  if (loader) {
    return <LoadingPageOverlay />;
  }

  if (contractSubmissionFailed) {
    return (
      <AddContract
        verificationError={submissionError}
        contractNumber={contractNumber}
        address={address}
      />
    );
  }

  if (verificationError) {
    return (
      <ErrorOverlay
        buttonLink="/"
        message={verificationError}
        handleClick={() => navigate("/")}
      />
    );
  }

  if (verificationCompleted) {
    return (
      <>
        <ThankYouOverlay
          onCloseModal={() => setOpenSuccesModal(false)}
          showModal={openSuccessModal}
          title={t.THANK_YOU}
          description={t.ADD_CONTRACT_THANK_YOU_TEXT}
          buttonText={t.BACK_HOME}
          buttonLink="/"
          handleClick={() => navigate("/")}
        />
      </>
    );
  }

  if(openSuccessModal) {
    return (
      <VerificationModal isOpen={openSuccessModal} onClose={() => onCloseModal()}/>
    )
   }

  return <div></div>;
};

export default inject("contractStore", "configStore")(observer(ContractVerificationHandler));
