import { Component } from "react";
import { observer, inject } from "mobx-react";
import withRouter from "./withRouter";
import IconsCloseSmall from "../assets/svg/iconsCloseSmall.svg";
import t from "../service/translate.service";
import moment from "moment";
import { orderBy, toUpper } from "lodash";
import { toJS } from "mobx";

class CertificateAlerts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: [],
    }
  }

  handleAlertCloseIconClick = (type) => {
    const updatedFilters = [...this.state.filters]
    updatedFilters.push(type)

    this.setState({ filters: updatedFilters })
  }

  handleAlertContentClick = () => {
    const { contractNumber } = this.props.useParams
    const { rentContractDocumentsEnabled } = this.props.configStore

    if (rentContractDocumentsEnabled && this.props.contractVerified) {
      this.props.navigate(`/rent-contract-documents/${contractNumber}`)
    }
  }

  getAlertJSX = (certificate) => {
    if (!certificate.requestedAt) return 
    const key = `${certificate.type.toUpperCase()}_${certificate.alertStatus.toUpperCase()}`
    const date = moment(certificate.requestedAt).format('DD.MM.YYYY')
    const translation = `CERTIFICATE_RENT_${key}`

    const components = {
      RC_SUCCESS: 
        <>
          <span>{t[`${translation}_MESSAGE_1`]} </span>
          <span>{date}</span>
          <span>{t[`${translation}_MESSAGE_2`]}</span>
          <span>{t[`${translation}_MESSAGE_3`]}{" "}</span>
          <span>{t[`${translation}_MESSAGE_4`]}</span>
        </>,
      RC_INFO: <>
        <span>{t[`${translation}_MESSAGE_1`]}</span>
      </>,
      RPC_INFO:
        <>
          <span>{t[`${translation}_MESSAGE_1`]}{" "}</span>
          <span>{date}</span>
          <span>{t[`${translation}_MESSAGE_2`]}</span>
        </>,
      RPC_SUCCESS:
        <>
          <span>{t[`${translation}_MESSAGE_1`]}{" "}</span>
          <span>{t[`${translation}_MESSAGE_2`]}</span>
        </>
    }

    return components[key]
  }

  render() {
    const { filters } = this.state
    const filteredAlerts = this.props.certificates.filter(c => !filters.includes(c.type) && c.requestedAt)
    const orderedAlerts = orderBy(filteredAlerts, ['requestedAt'], ['desc'])

    if (!orderedAlerts?.length) return

    return (
      <div className='homepage contract-overview'>
        <div className="alert-list">
          {orderedAlerts.map((certificate, i) => {
            return (
              <div
                className={
                  certificate.type === "general" || certificate.type === 'letter'
                    ? "itemGeneral notification-group"
                    : `item contract-overview-item-${certificate.alertStatus} notification-group`
                }
                key={certificate.type}
              >
                <div className={`${certificate.type === 'letter' ? 'letters' : ''} notification`}>
                  <button
                    className="close"
                    style={{ zIndex: '99' }}
                    onClick={() => this.handleAlertCloseIconClick(certificate.type)}
                  >
                    <img src={IconsCloseSmall} />
          
                  </button>
                  <div 
                    className="content" 
                    onClick={this.handleAlertContentClick} 
                    style={{ cursor: 'pointer '}}
                  >
                    <div className="message">
                      <div className="status">
                        {t[`CERTIFICATE_RENT_${certificate.type}_${toUpper(certificate.alertStatus)}`]}
                      </div>
                      <div className="jsx">
                        {this.getAlertJSX(certificate)}
                        {certificate.alertStatus !== 'success' && certificate.checking && (
                          <div className="progress-bar wrapper" style={{padding: "4px"}}>
                              <div className="progress-bar bar-container">
                                  <span className="progress-bar percentage">{certificate.percentage}%</span>
                                  <div className="progress-bar bar" style={{width: `${certificate.percentage}%`}}></div>
                              </div>
                          </div>
                        )}
                      </div>
                      <div className="date" style={{ marginTop: '1rem' }}>
                        {certificate.date}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  }
}
export default inject("alertStore", "configStore")(withRouter(observer(CertificateAlerts)));
  