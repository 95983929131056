import React from 'react'
import { observer } from 'mobx-react'
import t from '../../service/translate.service'
import { Checkbox } from '../../components/formComponents'
import { CheckIcon } from '../../components/icons'
import { Form, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import contractService from '../../service/contract.service'

const LoyaltyRegistrationForm = observer(({ store, contracts, contractSelect }) => {
  const { model, validators, setVal, onSubmit, contractSelected } = store

  return (
    <Form>

      <Form.Group className='actions text-center'>
        <div className='group'>
          <a
            href=''
            onClick={(e) => {
              if (e.key !== 'Tab') {
                e.preventDefault()
              }
              onSubmit(e)
            }}
            type='submit'
            className='button primary'
            style={{
              textDecoration: 'none',
              color: 'unset'
            }}
          >
              {t.LOYALTY_REGISTER}
          </a>
        </div>
      </Form.Group>

      <Form.Group>
        <label className='form-label'>{t.CONTRACT_LIST_TITLE}</label>
        {contracts.map(contract => {
          if(contractService.checkIsContractCondominium(contract?.contractNumber)) return
          return (
            <ContractCard key={contract.id}
              contract={contract}
              handleClick={contractSelect}
              active={model.contractNumber === contract.contractNumber} />
          )
        })}
        {contractSelected && (<div className='error-message loyalty-contract'>{t.NO_CONTRACT_SELECTED_ERROR}</div>)}
      </Form.Group>

      <Checkbox
        labelBeforeText={t.LOYALTY_TERMS_AND_CONDITIONS_1}
        labelLinkText={t.LOYALTY_TERMS_AND_CONDITIONS_2}
        labelLink='terms-and-conditions'
        value={model.terms}
        onChange={e => setVal('terms', !model.terms)}
        error={t.TERMS_AND_CONDITIONS_REQUIRED}
        validate={validators['terms']}
      />
    </Form>
  )
})

LoyaltyRegistrationForm.propTypes = {
  store: PropTypes.object,
  contracts: PropTypes.object,
  contractSelect: PropTypes.func
}
const ContractCard = ({ contract, handleClick, active }) => {
  return (
    <a
      href=''
      className={`loyalty-contract-card ${active && 'active'}`}
      onClick={(e) => {
        if (e.key !== 'Tab') {
          e.preventDefault()
        }
        handleClick(contract.contractNumber)
      }}
      style={{
        textDecoration: 'none',
        color: 'unset'
      }}
    >
      <div>
        <p className='number'>{contract.contractNumber}</p>
        <p>{contract.address}</p>
      </div>
      <div className='check'>
        <div>{CheckIcon}</div>
      </div>
    </a>
  )
}

ContractCard.propTypes = {
  contract: PropTypes.object,
  handleClick: PropTypes.func,
  active: PropTypes.bool
}
export default LoyaltyRegistrationForm
