import React from 'react'
import PrimaryTitle from '../../../../components/PrimaryTitle'
import { inject, observer } from 'mobx-react'
import { Input, DateInput, Textarea } from '../../../../components/formComponents'
import { Form, Button } from 'react-bootstrap'
import DocumentStore from '../../../../store/documentForm.store'
import PropTypes from 'prop-types'
import t from '../../../../service/translate.service'
import ContractSelector from '../../../../components/contractSelector'

const NameChange = (props) => {
  const { model, setVal, nameChangeValidators } = DocumentStore
  const { file } = props
  const onSubmit = async (e) => {
    e.preventDefault()
    DocumentStore.submit(file.fileName, props.contractStore.selectedContract.contractNumber)
  }
  const getAttrs = field => {
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: nameChangeValidators[field]
    }
  }
  return (
    <div className='form-holder'>
      <PrimaryTitle title={t.NAME_CHANGE} />
      <Form>
        <ContractSelector label padding page='documents' />
        <h3 className='inputs-text'>{t.NAME_CHANGE_HEAD_ONE}</h3>
        <Input name='fullName' {...getAttrs('fullName')} label={t.DIGITAL_CONTRACT_FORM_FULL_NAME + '*'} />
        <h3 className='inputs-text'>{t.NAME_CHANGE_HEAD_TWO}</h3>
        <Input name='firstName' {...getAttrs('nameChangeFirstName')} label={t.DIGITAL_CONTRACT_FORM_FIRST_NAME + '*'} />
        <Input name='lastName' {...getAttrs('nameChangeLastName')} label={t.DIGITAL_CONTRACT_FORM_LAST_NAME + '*'} />
        <Textarea name='nameChangeReason' {...getAttrs('nameChangeReason')} label={t.DIGITAL_CONTRACT_FORM_REASON + '*'} />
        <p className='inputs-text'>{t.NAME_CHANGE_PARA_ONE}</p>
        <Input name='place' {...getAttrs('place')} label={t.DIGITAL_CONTRACT_FORM_PLACE + '*'} />
        <DateInput name='date' value={model['date']} onChange={(date) => setVal('date', date)} validate={nameChangeValidators['date']} label={t.DIGITAL_CONTRACT_FORM_DATE + '*'} />
        <Form.Group className='actions text-center'>
          <div className='group'>
            <a
              href=''
              className='button primary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                onSubmit(e)
              }}
              style={{
                textDecoration: 'none',
                color: 'unset'
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_SEND}
            </a>
            <a
              href='/documents'
              className='button secondary'
              onClick={(e) => {
                if (e.key !== 'Tab') {
                  e.preventDefault()
                }
                DocumentStore.onCloseForm()
              }}
              style={{
                textDecoration: 'none',
                color: 'unset'
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_CANCEL}
            </a>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

NameChange.propTypes = {
  file: PropTypes.object.isRequired,
  contractStore: PropTypes.object
}

export default inject('contractStore')(observer(NameChange))
