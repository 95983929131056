import React, {Component} from 'react'
import rentContractDocumentStore from "../../store/rentContractDocuments.store";
import withRouter from "../../components/withRouter";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import { observer } from "mobx-react";
import { Alert } from 'antd';
import t from "../../service/translate.service";
import { extractError, extractErrorFromSap } from '../../service/response.service';

class RentContractDocumentsPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      loading: null,
      pdfUrl: null,
    }
  }

  componentDidMount() {
    (async () => {
      window.scrollTo(0, 0)
      this.setState({ loading: true })

      const { contractNumber, docId } = this.props.useParams
      try {
        const blob = await rentContractDocumentStore.getDocument(contractNumber, docId);
        this.setState({ pdfUrl: URL.createObjectURL(blob) })
      } catch (err) {
        this.setState({ error: extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'] })
      }

      this.setState({ loading: false })
    })()
  }

  componentWillUnmount() {
    if (this.state.pdfUrl) {
      URL.revokeObjectURL(this.state.pdfUrl);
    }
  }

  handleClosePreview() {
    const { contractNumber } = this.props.useParams
    this.props.navigate(`/rent-contract-documents/${contractNumber}`)
  }

  render() {
    if (this.state.loading) return <LoadingPageOverlay />

    return (
      <div>
        {this.state.error && <Alert 
          message={this.state.error} 
          type='error' 
          style={{marginTop: '12px', marginBottom: '12px'}} 
        />}

        <div className='position-relative' style={{minHeight: '900px'}}>
          <div className='document-preview'>
            <button className='close-preview' onClick={() => this.handleClosePreview()}>x</button>
            <div className='text-center'>
              {this.state.pdfUrl && <iframe 
                title="PDF Viewer"
                width="100%"
                height="100%"
                style={{ border: "none" }} 
                src={this.state.pdfUrl} 
              />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(RentContractDocumentsPreview))