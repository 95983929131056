// 'use strict';
import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { b2cPolicies } from '../../azureB2C/authConfig'

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const { instance } = useMsal()
  const language = localStorage.getItem('locale') || 'de'
  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const back = urlParams.get('back');

      setLoading(true)
      if (back) {
        localStorage.removeItem('redirected')
        localStorage.removeItem('auth_grant')
        await instance.logout()
        window.location.replace('/login')
      } else {
        const redirected = localStorage.getItem('redirected')
        if (!redirected) {
          localStorage.setItem('redirected', 'true')
          await instance.acquireTokenRedirect({
            ...b2cPolicies.authorities.emailForgotPassword,
            scopes: [process.env.REACT_APP_AZ_CLIENT_ID || ''],
            extraQueryParameters: {'ui_locales': `${language}`}
          })
        } else {
          localStorage.removeItem('redirected')
          localStorage.removeItem('auth_grant')
          await instance.logout()
          window.location.replace('/login')
        }
      }
    })()
  }, [])
  return (
    <div>
      {loading && (
        <LoadingPageOverlay />
      )}
    </div>
  )
}

export default ForgotPassword
