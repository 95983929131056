/**
 * Will contain methods for sending RepairsAndInquiriesNewRequest
 */
import { extractResponse, extractError, extractErrorFromSap } from '../service/response.service'
import { extendObservable, runInAction } from 'mobx'
import find from 'lodash/find'
import { api } from '../service/api.service'
import t from '../service/translate.service'
import { buildValidators, minCharWithoutSpace, required } from '../service/validate'
import DOMPurify from 'dompurify'

const R_AND_I_REPORT_ENDPOINT = '/damage-report'
const APARTMENT_MAINTENANCE = 5

class RepairsAndInquiriesFormStore {
  constructor() {
    extendObservable(this, {
      model: {
        subject: '',
        description: '',
        repairAndInquiryCategoryId: APARTMENT_MAINTENANCE,
        urgent: false
      },
      files: [],
      categories: [],
      loading: false,
      submitting: false,
      submitted: false,
      submitSuccess: false,
      error: ''
    })

    window.store = this
    this.loadCategories()
  }

  async loadCategories() {
    this.loading = true
    try {
      const response = await api.get(`/repair-and-inquiry-categories`)
      const data = extractResponse(response)
      const categories = data.map(category => {
        return {
          value: category.id,
          label: t.locale === 'en' ? category.name : category.nameDe
        }

      })
      this.categories.replace(categories)
      this.loading = false
    } catch (err) {
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.loading = false
    }
  }

  setVal = (field, value) => {
    switch(field) {
      case 'description':
      case 'subject':
        value =  DOMPurify.sanitize(value)
        break;
      default:
        value = value
    }
    this.model[field] = value
  }

  get validators() {
    return buildValidators(
      {
        subject: [required, minCharWithoutSpace(3)],
        description: [required]
      },
      this.submitted
    )
  }

  addFile(files) {
    if (files.length) {
      files.forEach(file => this.files.push(file))
      this.error = ''
    } else {
      this.error = t.INVALID_FILE_TYPE
    }
  }

  async submit(contractNumber) {
    runInAction(() => {
      this.submitting = true
      this.submitted = true
      this.model.repairAndInquiryCategoryId = this.model.repairAndInquiryCategoryId.value || this.model.repairAndInquiryCategoryId
    })

    const isValid = !find(this.validators, (validate, field) =>
      validate(this.model[field])
    )
    if (!isValid) {
      runInAction(() => this.submitting = false)
      return
    }

    const data = new FormData()

    for (const key of Object.keys(this.model)) {
      data.append(key, this.model[key])
    }
    data.append('contractNumber', contractNumber)

    this.files.forEach((file, i) => data.append(`file_${i}`, file, file.name))
    return api
      .post(R_AND_I_REPORT_ENDPOINT, data, {
        timeout: 300000, headers:
        {
          'Content-Type': `multipart/form-data;`
        }
      })
      .then(res => {
        runInAction(() => {
          this.submitting = false
          this.submitSuccess = true
        })
      })
      .catch(err => {
        runInAction(() => this.submitting = false)
        if (err.crossDomain) this.error = t.EXTERNAL_FILE_ERROR
        this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
        window.scrollTo(0, 0)
      })
  }
}

export default RepairsAndInquiriesFormStore
