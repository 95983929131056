import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import { Link } from 'react-router-dom'
import NoContent from '../../components/NoContent'
import t from '../../service/translate.service'
import LoyaltyStore from '../../store/loyalty.store'
import MobxReactForm from 'mobx-react-form'
import validator from 'validatorjs'
import PrimaryTitle from '../../components/PrimaryTitle'
import PropTypes from 'prop-types'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { LoyaltyFields, LoyaltyView, LoyaltyRegistrationForm } from '../Loyalty'
import { LoyaltyImage } from '../../components/icons'
import LoyaltyMaintenance from './LoyaltyMaintenance'
import LoyaltyQueueThankYou from './LoyaltyQueueThankYou'
import withRouter from '../../components/withRouter'
import historyService from '../../service/history.service'
import { Alert } from 'antd'

const plugins = { dvr: validator }

class LoyaltyPage extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      store: new LoyaltyStore()
    })

    this.contracts = this.props.contractStore.activeContracts
    if (this.contracts.length === 1) {
      this.store.contractSelect(this.contracts[0].contractNumber)
    }
    this.form = new MobxReactForm({ LoyaltyFields }, { plugins })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  handleFAQClick = () => {
    this.props.navigate('/loyalty-program/faq')
  }

  render() {
    if (!this.props.contractStore.selectedContract) {
      return (
        <div>
          <PrimaryTitle title={t.LOYALTY_PROGRAM} />
          <div className='text-center'>
            <NoContent message={t.NO_CONTRACTS} />
            <Link to='/contracts/add-contract'
              className='primary button'
              style={{ marginBottom: '30px' }}
              onClick={() => historyService.push('/contracts/add-contract')}>{t.ADD_A_CONTRACT_BUTTON}</Link>
          </div>
        </div>
      )
    }

    const loyaltyAvailable = true

    if (!loyaltyAvailable && this.store.userQueuedUp) {
      return (
        <div>
          <LoyaltyQueueThankYou />
        </div>
      )
    }

    // if(this.store.accountError || this.store.error) {
    //   return (
    //     <Alert message={this.store.error} type='error' style={{marginTop: '12px', marginBottom: '12px'}}/>
    //   )
    // }

    return (
      <div>
        {this.store.loading && <LoadingPageOverlay />}

        {this.store.userRegistered ? (
          <div>
            {loyaltyAvailable ? (
              <LoyaltyView store={this.store}
              navigation={this.props.navigate}
              location={this.props.location}
                handleClick={this.handleFAQClick}
                unregisterFromLoyaltyProgram={this.store.unregisterFromLoyaltyProgram} />
            ) : (
              <LoyaltyMaintenance />
            )}
          </div>
        ) : (
          <div>
            <div className='text-center'>
              <div className='loyalty-register-image'>{LoyaltyImage}</div>
              <PrimaryTitle title={t.LOYALTY_TITLE} />
              <div className='loyalty-subtitle'>{t.LOYALTY_SUBTITLE}</div>
            </div>
            {this.store.error && <Alert message={this.store.error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
            <LoyaltyRegistrationForm store={this.store} contracts={this.contracts} contractSelect={this.store.contractSelect} />
            <div className='text-center'>
              <a
                href=''
                onClick={(e) => {
                  if (e.key !== 'Tab') {
                    e.preventDefault()
                  }
                  this.handleFAQClick()
                }}
                className='button tertiary'
                style={{
                  textDecoration: 'none',
                  color: 'unset'
                }}
              >
                  FAQ
              </a>
            </div>
          </div>
        )}
      </div>
    )
  }
}
LoyaltyPage.propTypes = {
  contractStore: PropTypes.object
}

export default inject('contractStore')(withRouter(observer(observer(LoyaltyPage))))
